import { styled } from "styled-components";

export const CurrentPageText = styled.div`
  display: flex;
  height: 290px;
  justify-content: center;
  align-items: center;
  .current-name {
    font-size: 28px;
    font-weight: bold;
    color: white;
  }
`;
