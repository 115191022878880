import React from "react";
import {
  Address,
  CompanyInformation,
  FooterContainer,
  FooterLayout,
  Title,
} from "./style";
import footerImg from "./img/footerImg.png";

const Footer = () => {
  return (
    <FooterLayout>
      <FooterContainer>
        <CompanyInformation>
          <p className="company-name">(주)FEC Freedom of Electric Car</p>
          <p>본사 : 경북 경산시 강변서로53길 23, 2층</p>
          <pre>
            {`서울지점 : 서울특별시 송파구 백제고분로 67, 607호
                (잠실동, 위너스오피스텔2)`}
          </pre>
          <p>공장 : 경북 경산시 진량읍 일연로 502-13</p>
          <p>실증사업지 : 경북 경산시 강변서로 51길 49</p>
          <p>T. 053-719-4967 홈페이지 : feccharge.co.kr</p>
        </CompanyInformation>
        <Address>
          <img src={footerImg} alt="footerImg" />
        </Address>
      </FooterContainer>
    </FooterLayout>
  );
};

export default Footer;
