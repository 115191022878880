import { styled } from "styled-components";

// Header.js
export const HeaderLayout = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
`;
export const HeaderContainer = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 1400px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CompanyName = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  padding-top: 15px;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    .en {
      margin-left: 3px;
      font-size: 14px;
      margin-top: 15px;
    }
    .ko {
      color: white;
    }
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    a {
      .en {
        font-size: 12px;
        margin-top: 12px;
      }
    }
  }
`;

// export const MainTitleWrap = styled.div`
//   height: 610px;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   #title {
//     font-size: 25px;
//     color: white;
//     font-weight: bold;
//   }
// `;
// export const MainTitle = styled.div`
//   margin-top: 30px;
//   color: white;
//   font-size: 48px;

//   .main-title {
//     margin-top: 12px;
//     span:nth-child(2) {
//       font-weight: bold;
//     }
//   }
// `;
// export const SubTitle = styled.div`
//   margin-top: 44px;
//   color: white;
//   span {
//     display: block;
//     line-height: 25px;
//     opacity: 0.7;
//   }
// `;

export const MobileMenuBtn = styled.div`
  display: none;
  font-size: 20px;
  color: white;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;
