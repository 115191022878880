import { styled } from "styled-components";
import { CompanyIntroductionLayout } from "../common/style";

export const GreetingTitle = styled.div`
  font-size: 20px;
  line-height: 25px;
  h1 {
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 20px;
  }
`;
export const GreetingContainer = styled(CompanyIntroductionLayout)``;

export const GreetingText = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: white;
  position: relative;
  font-size: 20px;
  p {
    line-height: 25px;
    margin-bottom: 12px;
  }
  .text-list {
    display: flex;
    h3 {
      white-space: nowrap;
      margin-right: 20px;
    }
    div {
      width: 100%;
      p > span {
        font-weight: 700;
      }
    }
  }
`;
