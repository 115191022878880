import React from "react";
import { ContentCard, Main } from "./style";
import CompanyContentIndex from "./components/CompanyContent/CompanyContentIndex";
import Information from "./components/Information/Information";

const IndexContent = () => {
  return (
    <Main>
      <CompanyContentIndex />
    </Main>
  );
};

export default IndexContent;
