import {
  PopupModalBack,
  PopupModalBox,
  PopupModalCloseBox,
  PopupModalCloseBtn,
} from "./PopupModal.style";
import popupPhoto from "./img/popupPhoto.png";
const PopupModal = ({ onclickCloseModal, onclickOneDayClose }) => {
  return (
    <PopupModalBack>
      <PopupModalBox>
        <img src={popupPhoto} alt="popupPhoto" />
        <PopupModalCloseBox>
          <PopupModalCloseBtn onClick={onclickOneDayClose}>
            확인 (오늘 하루동안 보지 않기 )
          </PopupModalCloseBtn>
          <PopupModalCloseBtn onClick={onclickCloseModal}>
            확인
          </PopupModalCloseBtn>
        </PopupModalCloseBox>
      </PopupModalBox>
    </PopupModalBack>
  );
};

export default PopupModal;
