import React from "react";
import { MainLayout } from "../../common/style/styled";
import { Content } from "../common/style";
import { useRequireLogin } from "../../../hooks/useRequireLogin";
import Footer from "../../Footer/Footer";
import { THeader, Table, Tdescription, Trow } from "./style";

const UsageHistory = () => {
  //로그인 안되어있으면 접속 x
  useRequireLogin();
  return (
    <>
      <MainLayout>
        <Content>
          <Table>
            <THeader>이용날짜</THeader>
            <THeader>충전소 이름</THeader>
            <THeader>충전 시작시각</THeader>
            <THeader>충전량</THeader>
            <THeader>금액</THeader>
            <Trow>
              <Tdescription>2022-03-01</Tdescription>
              <Tdescription>경산 옥산 주차장</Tdescription>
              <Tdescription>23시 41분</Tdescription>
              <Tdescription>100KWH</Tdescription>
              <Tdescription>5,000원</Tdescription>
            </Trow>
            <Trow>
              <Tdescription>2022-03-01</Tdescription>
              <Tdescription>경산 옥산 주차장</Tdescription>
              <Tdescription>23시 41분</Tdescription>
              <Tdescription>100KWH</Tdescription>
              <Tdescription>5,000원</Tdescription>
            </Trow>
            <Trow>
              <Tdescription>2022-03-01</Tdescription>
              <Tdescription>경산 옥산 주차장</Tdescription>
              <Tdescription>23시 41분</Tdescription>
              <Tdescription>100KWH</Tdescription>
              <Tdescription>5,000원</Tdescription>
            </Trow>
            <Trow>
              <Tdescription>2022-03-01</Tdescription>
              <Tdescription>경산 옥산 주차장</Tdescription>
              <Tdescription>23시 41분</Tdescription>
              <Tdescription>100KWH</Tdescription>
              <Tdescription>5,000원</Tdescription>
            </Trow>
          </Table>
        </Content>
      </MainLayout>
      <Footer />
    </>
  );
};

const useHistory = [
  {
    date: "이용날짜",
    chargename: "충전소이름",
    chargestart: "충전 시작시각",
    chargeamount: "충전량",
    chargeprice: "금액",
  },
];
export default UsageHistory;
