import React, { useEffect } from "react";
import { Map } from "./style";
const { kakao } = window;


const KaKaoMap = ({ width, height }) => {
    useEffect(() => {
        const container = document.getElementById('map')
        const options = {
            center: new kakao.maps.LatLng(35.82939280880806, 128.726312970346648),
            level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(35.82939280880806, 128.726312970346648),
        });
        marker.setMap(map);
    }, [])
    return (
        <>

            <Map width={width} height={height} id='map' />
        </>
    )
}

export default KaKaoMap;