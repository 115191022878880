import { Card, Icon, CardTitle, CardText } from "./style";

const AdvantagesCard = ({ imgUrl, title, subTitle, description }) => {
    return (
        <Card>
            <Icon><img src={imgUrl} /></Icon>
            <CardTitle>
                <h3>{title}</h3>
                <p>{subTitle}</p>
            </CardTitle>
            <CardText>
                {description}
            </CardText>
        </Card>
    )
}

export default AdvantagesCard;