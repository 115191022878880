import styled from "styled-components";

export const CompanyContent = styled.div`
  padding: 0 20px;
  height: 250px;
  display: flex;
  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
  }
`;

export const ContentItem = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &:hover {
    background-color: rgba(199, 199, 199, 0.4);
  }
  .item-number {
    margin-bottom: 5px;
  }
  .company-sub__content {
    /* height: 120px; */
    display: flex;
    flex-direction: column;
    span {
      line-height: 23px;
      display: block;
      margin-bottom: 5px;
      opacity: 0.5;
      font-size: 18px;
    }
  }
  span {
    line-height: 32px;
    display: block;
    font-weight: bold;
    font-size: 28px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 120px;
    padding: 0;
    justify-content: center;
    .company-name {
      font-size: 15px;
    }
  }
  @media screen and (max-height: 800px) {
    .company-sub__content {
      /* height: 120px; */
      display: flex;
      flex-direction: column;
      span {
        line-height: 14px;
        display: block;
        margin-bottom: 5px;
        opacity: 0.5;
        font-size: 12px;
      }
    }
    span {
      display: block;
      font-weight: bold;
      font-size: 20px;
    }
  }
`;
