import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";
import {
  CertificateItem,
  CertificateItemBox,
  CertificateLayout,
} from "./Certificate.style";
import certificate1 from "./certificate1.png";
import certificate2 from "./certificate2.png";
import certificate3 from "./certificate3.png";
import certificate4 from "./certificate4.png";
import certificate5 from "./certificate5.png";

const certificateData = [
  certificate1,
  certificate2,
  certificate3,
  certificate4,
  certificate5,
];
const Certificate = () => {
  return (
    <>
      <MainLayout>
        <CertificateLayout>
          <CertificateItemBox>
            {certificateData.map((item) => {
              return (
                <CertificateItem>
                  <img src={item} alt="photo" />
                </CertificateItem>
              );
            })}
          </CertificateItemBox>
        </CertificateLayout>
      </MainLayout>

      <Footer />
    </>
  );
};

export default Certificate;
