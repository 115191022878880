import React, { useEffect, useState } from "react";
import { MainTitleWrap, MainTitle, SubTitle, MobileMenuBtn } from "./style.js";
import { CompanyName, HeaderContainer, HeaderLayout } from "./style.js";
import {
  NavigateSubBox,
  NavigateSubList,
  NavigateSubListItem,
  TechnicalVideo,
} from "./components/style.js";
import Navigate from "./components/Navigate.js";
import { Wrapper } from "../../common/style/styled.js";
import { Link, useLocation } from "react-router-dom";
import headerIcon from "./brandIcon.png";
import { content } from "./content.js";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage.js";
import MobileNavigate from "./components/MobileNavigate.js";
const Header = ({ userInfo, error, loading }) => {
  const [isViewHeader, setIsViewHeader] = useState(window.scrollY > 10);
  const [isMobileMenuBtn, setIsMobileMenuBtn] = useState(false);
  const { path } = useLocation();
  useEffect(() => {
    setIsMobileMenuBtn(false);
  }, [path]);
  useEffect(() => {
    const moveScroll = (e) => {
      console.log(window.scrollY);
      setIsViewHeader(window.scrollY > 10);
    };
    window.addEventListener("scroll", moveScroll);

    return () => {
      window.removeEventListener("scroll", moveScroll);
    };
  }, []);
  return (
    <HeaderLayout>
      <HeaderContainer>
        <CompanyName>
          {/* <a
            href="/"
            style={{ width: "80px", height: "30px", marginRight: "10px" }}
          >
            <img src={headerIcon} style={{ width: "100%", height: "100%" }} />
          </a> */}
          <Link to="/">
            <div className="ko">(주) 에프이씨</div>
            <div className="en">FEC Freedom of Electric Cars</div>
          </Link>
        </CompanyName>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Navigate userInfo={userInfo} error={error} loading={loading} />
          <ChangeLanguage />
        </div>
        <MobileMenuBtn
          onClick={() => {
            setIsMobileMenuBtn((prev) => !prev);
          }}
        >
          {isMobileMenuBtn ? (
            <span class="material-symbols-outlined">close</span>
          ) : (
            <span class="material-symbols-outlined">menu</span>
          )}
        </MobileMenuBtn>
        {isMobileMenuBtn && <MobileNavigate />}
      </HeaderContainer>

      {/* <MainTitleWrap>
        <h1 id="title">Freedom of Electric Cars</h1>
        <MainTitle>
          <div className="main-title">
            <span>미래를 위한 차세대 전기차충전 시스템</span>
          </div>
        </MainTitle>
        <SubTitle>
          <span>
            전기차를 편안하고 신속하게 충전할 수 있는 미래를 향해 달려갑니다.
          </span>
        </SubTitle>
      </MainTitleWrap> */}
    </HeaderLayout>
  );
};

export default Header;
