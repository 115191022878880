import { useForm } from "react-hook-form";
import {
  CardContainer,
  MenuCard,
} from "../../CompanyIntroduction/Greeeting/style";
import Footer from "../../Footer/Footer";
import SubMenuCard from "../../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../../common/style/styled";
import AuthForm from "../common/AuthForm/AuthForm";
import AuthInput from "../common/AuthInput/AuthInput";
import { Content, InputContainer } from "../common/style";
import { AuthLoginApi } from "../../../api/api";

import { Oval } from "react-loader-spinner";
import AuthButton from "../common/AuthButton/AuthButton";
import AuthErrorMsg from "../common/AuthErrorMsg/AuthErrorMsg";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AuthLogin = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const { isError, isLoading, loginPost } = AuthLoginApi();
  const handlerLoginSubmit = async (data) => {
    await loginPost(process.env.REACT_APP_API_USER_LOGIN, data, "/");
  };
  const { userInfo } = useSelector((state) => state.userInfo);

  // user 정보가 없으면 홈으로 이동
  if (!userInfo) {
    alert("준비단계 중 입니다.");
    window.location.href = "/";
  }

  return (
    <>
      <MainLayout>
        <Content>
          <AuthForm onSubmit={handleSubmit(handlerLoginSubmit)}>
            <InputContainer>
              <AuthInput
                type="email"
                text="이메일"
                placeholder="이메일"
                register={register("email", {
                  required: "이메일을 입력해주세요.",
                })}
              />
            </InputContainer>
            <InputContainer>
              <AuthInput
                type="password"
                text="비밀번호"
                placeholder="비밀번호"
                register={register("password", {
                  required: "비밀번호를 입력해주세요.",
                })}
              />
              {isError && <AuthErrorMsg>{isError.error}</AuthErrorMsg>}
              {errors.password && (
                <AuthErrorMsg>{errors.password.message}</AuthErrorMsg>
              )}
            </InputContainer>
            <AuthButton isLoading={isLoading} text="로그인" type="submit" />
          </AuthForm>
        </Content>
      </MainLayout>
      <Footer />
    </>
  );
};

export default AuthLogin;
