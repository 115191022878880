import { styled } from "styled-components";

export const Introduction = styled.div`
   
`
export const Explanation = styled.div`
    font-size:25px;
    text-align:center;
    line-height:32px;
    .main-title{
        font-size: 14px;
        letter-spacing: 2px;
        font-weight:bold;
        color:#333;
    }
    .title{
        color:#aaa;
        margin-bottom:10px;
    }
    .title-text{
        color:#333;
        strong{
            font-size:29px;
            font-weight:bold;
            margin-right:7px;
        }   
    }
    #line{
        width:1px;
        height:30px;
        margin:0 auto;
        margin-top:20px;
        margin-bottom:20px;
        border-right:1px solid #d1d1d1;
    }
`