import React, { useEffect } from "react";
import { MainLayout } from "../../common/style/styled";
import { Content, InputContainer } from "../common/style";
import AuthForm from "../common/AuthForm/AuthForm";
import AuthInput from "../common/AuthInput/AuthInput";
import { useForm } from "react-hook-form";
import { AuthPatchchApi } from "../../../api/api";
import AuthButton from "../common/AuthButton/AuthButton";
import { useLocation } from "react-router-dom";
import AuthErrorMsg from "../common/AuthErrorMsg/AuthErrorMsg";
import Cookies from "js-cookie";

const AuthEdit = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm();
  const { isError, patchPost, isLoading } = AuthPatchchApi();
  const location = useLocation();
  const state = location.state;
  const userInfo = state.type.user;

  useEffect(() => {
    if (userInfo?.name) setValue("name", userInfo?.name);
    if (userInfo?.email) setValue("email", userInfo?.email);

    if (userInfo?.home_addr) setValue("home_addr", userInfo?.home_addr);
  }, []);

  const handlerAuthEditSubmit = (data) => {
    patchPost(process.env.REACT_APP_API_USER_INFO, data);
  };
  return (
    <>
      <MainLayout>
        <Content>
          <AuthForm onSubmit={handleSubmit(handlerAuthEditSubmit)}>
            <InputContainer>
              <AuthInput
                text="이름"
                placeholder={userInfo?.name}
                id="name"
                register={register("name")}
              />
            </InputContainer>
            <InputContainer>
              <AuthInput
                text="이메일"
                id="email"
                register={register("email")}
                placeholder={userInfo?.email}
              />
            </InputContainer>
            <InputContainer>
              <AuthInput
                text="주소"
                id="home_addr"
                register={register("home_addr")}
                placeholder={userInfo?.home_addr}
              />
            </InputContainer>
            {isError && <AuthErrorMsg>{isError.error}</AuthErrorMsg>}
            <AuthButton type="submit" text="수정하기" isLoading={isLoading} />
          </AuthForm>
        </Content>
      </MainLayout>
    </>
  );
};

export default AuthEdit;
