import React, { useEffect, useRef, useState } from "react";
import {
  DetailContainer,
  DetailImage,
  DetailText,
  FieldContainer,
  FieldTitle,
  ListBtn,
  WriterContainer,
} from "../../Service/components/FieldDetail/style";
import Footer from "../../Footer/Footer";
import SubMenuCard from "../../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../../common/style/styled";
import { Link, useParams } from "react-router-dom";

const Post = () => {
  const [isNotice, setIsNotice] = useState({});

  const { id } = useParams();
  const noticeTitleRef = useRef(null);
  const date = new Date(isNotice[id] && isNotice[id].posttime);
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const noticeArr = Object.values(isNotice);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_NOTICE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      if (response.status === 200) {
        return setIsNotice(responseData);
      }
      if (response.status === 400) {
        return setIsNotice(responseData.error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (noticeTitleRef.current) {
      noticeTitleRef.current.innerHTML = noticeArr
        .map((item) => item.text)
        .join(" ");
    }
  }, [noticeArr]);
  return (
    <>
      <MainLayout>
        <FieldContainer>
          <FieldTitle>
            <h2>{isNotice[id] && isNotice[id].title}</h2>
          </FieldTitle>
          <WriterContainer>
            <div className="writer-container">
              <p>작성자 |</p>
              <p> 관리자</p>
            </div>
            <div className="writer-container">
              <p>등록일 |</p>
              <p>
                {" "}
                {year}-{month}-{day}
              </p>
            </div>
          </WriterContainer>
          <DetailContainer>
            <DetailText id="notice-title">
              <p>
                {isNotice &&
                  isNotice[id] &&
                  isNotice[id].text.split("/n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== isNotice[id].text.split("/n").length - 1 && (
                        <br />
                      )}
                    </React.Fragment>
                  ))}
              </p>
            </DetailText>
            <ListBtn>
              <Link to={"/notice"}>목록</Link>
            </ListBtn>
          </DetailContainer>
        </FieldContainer>
      </MainLayout>
      <Footer />
    </>
  );
};

export default Post;
