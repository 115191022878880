import React from "react";
import { DetailContainer, DetailTextContainer, NumberBox, NumberTitle, NumberTitleContainer } from "./style";

const DetailNumber = ({ number, title, description }) => {
    return (
        <DetailContainer>
            <NumberTitleContainer>
                <NumberBox>{number}</NumberBox>
                <NumberTitle>{title}</NumberTitle>
            </NumberTitleContainer>
            <DetailTextContainer>
                <p>{description}</p>
            </DetailTextContainer>
        </DetailContainer>
    )
}

export default DetailNumber;