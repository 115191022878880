import React from "react";
import { Input, Label } from "./style";
const AuthInput = ({ type, placeholder, register, id, text,value }) => {
    return (
        <>
            <Label htmlFor={id}>{text}</Label>
            <Input
                id={id}
                type={type} 
                placeholder={placeholder}
                {...register}
                value={value}

            />
        </>

    )
}

export default AuthInput;