import React, { useEffect } from "react";
import { MainLayout } from "../../common/style/styled";
import { Content, InputContainer } from "../common/style";
import AuthForm from "../common/AuthForm/AuthForm";
import AuthInput from "../common/AuthInput/AuthInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../../store/userInfoReducer";
import AuthButton from "../common/AuthButton/AuthButton";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import { useRequireLogin } from "../../../hooks/useRequireLogin";

const AuthProfile = () => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  // 로그인이 안되어 있을 경우에는 접속 x
  useRequireLogin();
  return (
    <>
      <MainLayout>
        <Content>
          <AuthForm>
            <InputContainer>
              <AuthInput
                text="이름"
                value={userInfo && userInfo?.user.name}
                id="name"
              />
            </InputContainer>
            <InputContainer>
              <AuthInput
                text="이메일"
                id="email"
                value={userInfo && userInfo?.user.email}
              />
            </InputContainer>
            <InputContainer>
              <AuthInput
                text="휴대폰번호"
                id="phone"
                value={userInfo && userInfo?.user.phone}
              />
            </InputContainer>
            <InputContainer>
              <AuthInput
                text="주소"
                id="home_addr"
                value={userInfo && userInfo?.user.home_addr}
              />
            </InputContainer>
            <Link to={"/auth/edit"} state={{ type: userInfo }}>
              <AuthButton text="정보수정 하기" />
            </Link>
          </AuthForm>
        </Content>
      </MainLayout>
      <Footer />
    </>
  );
};

export default AuthProfile;
