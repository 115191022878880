import { ErrorMsg } from "./style";

const AuthErrorMsg = ({ children }) => {
    return (
        <ErrorMsg>
            {children}
        </ErrorMsg>
    )
}

export default AuthErrorMsg;