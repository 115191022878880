import { styled } from "styled-components";

export const FooterLayout = styled.footer`
  height: 250px;
  background: #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    height: auto;
  }
`;
export const FooterContainer = styled.div`
  /* padding: 50px 20px; */
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 50px 20px;
  }
`;
export const CompanyInformation = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 25px;
  .company-name {
    font-size: 23px;
    margin-bottom: 10px;
  }
  p,
  pre {
    color: #9b9b9b;
  }
  @media screen and (max-height: 1024px) {
    font-size: 12px;
    line-height: 20px;
    .company-name {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
    margin-right: 0;
    margin-bottom: 30px;
  }
`;
export const Address = styled.address`
  height: 230px;
  color: #9c9c9c;
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-height: 1024px) {
    height: 150px;
  }
`;
