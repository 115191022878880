import { styled } from "styled-components";

export const InfoWrap = styled.div``;
export const Container = styled.div`
  display: flex;
`;
export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  span {
    display: block;
    font-weight: bold;
    font-size: 28px;
  }
`;
export const Recenthidden = styled.div`
  width: 310px;
  height: 300px;
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;

  /* left: ${(props) => props.currentSlide};
    transition: left 0.5s; // 부드러운 애니메이션을 위한 transition 추가 */
`;
export const RecentContact = styled.div`
  /* transform: translateX(${(props) => props.currentSlide}px); */

  position: relative;
  /* left: ${(props) =>
    -props.currentSlide *
    100}%; // 현재 슬라이드에 따라 left 포지션을 변경하여 슬라이드 효과를 생성 */

  left: ${(props) =>
    props.currentSlide}px; // 현재 슬라이드에 따라 left 포지션을 변경

  transition: left 0.5s; // 부드러운 애니메이션을 위한 transition 추가
  .recent-content {
    left: 40px;
    bottom: 42px;
    position: absolute;

    margin-bottom: 30px;
    .recent-content__title {
      font-size: 24px;

      font-weight: bold;
      z-index: 1;
    }
    .more-view {
      position: absolute;
      bottom: -40px;
      padding: 4px;
      font-size: 14px;
      border: 1px solid gray;
      transition: all 0.6s;
      &:hover {
        border: none;
        background-color: black;
        color: white;
      }
    }
  }
  img {
    width: 310px;
    height: 100%;
  }
`;
export const RecentNotice = styled.div`
  width: 300px;
  height: 100%;
`;
export const NewNoticeTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NewNotice = styled.div`
  width: 100%;
  padding: 0px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  .notice-description {
    line-height: 24px;
  }
  .new-notice {
    width: 100%;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 33px 0px 62px 0px;
    .new-notice__container {
      display: flex;
      justify-content: space-between;
    }
    .new-notice__title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 32px;
    }
    span {
      opacity: 0.4;
      font-weight: 700;
    }
  }

  .new-notice__sub {
    padding-bottom: 26px;
    h5 {
      padding: 30px 0px;
      font-weight: 900;
    }
    span {
      font-size: 14px;
      opacity: 0.4;
      font-weight: 700;
    }
  }
  p {
    text-align: center;
    opacity: 0.4;
    font-weight: 600;
  }
`;
export const AdressInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const NumberContainer = styled.div`
  height: 100%;
  color: white;
  display: flex;
  padding-top: 22px;
  flex-direction: column;
  align-items: center;
  background-image: url("/img/cs_bg.jpg");
  h4 {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

export const MapAPi = styled.div`
  background-color: orange;
  height: 100%;
`;
export const ContactDetailsBox = styled.div`
  text-align: center;
  .company-email {
    color: #828282;
  }
  p {
    font-size: 18px;
    font-weight: 600;
  }
  p:nth-child(1) {
    font-size: 30px;
    margin-bottom: 12px;
  }
`;
