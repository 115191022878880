import { styled } from "styled-components";

//AuthWrapper
export const Content = styled.div`
    margin-top:58px;
`

// common
export const InputContainer = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:30px;
`



