import { Oval } from "react-loader-spinner";
import { AuthBtn } from "./style";

const AuthButton = ({ isLoading, text, type }) => {
    return (
        <>
            {/* isLoading props로 받은 뒤 로딩 상태를 확인한다. */}
            {isLoading ?
                <AuthBtn type={type}>
                    <Oval
                        color='white'
                        width={25}
                        height={25}
                    />
                </AuthBtn>
                :
                <AuthBtn>{text}</AuthBtn>
            }
        </>
    )
}

export default AuthButton;