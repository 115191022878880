import { styled } from "styled-components";
import { TechnicalLayout } from "../style/Technical.style";

export const CertificateLayout = styled(TechnicalLayout)`
  padding: 50px 20px;
`;

export const CertificateItemBox = styled.div`
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CertificateItem = styled.div`
  width: 100%;
  height: 400px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    height: 300px;
  }
  @media screen and (max-width: 550px) {
    height: 350px;
  }
`;
