import React from "react";
import { CardContainer, MenuCard } from "../Greeeting/style";
import {
  CompanyHistory,
  CompanyHistoryItem,
  Container,
  HistoryContainer,
  HistoryTitle,
} from "./style";
import Footer from "../../Footer/Footer";
import SubMenuCard from "../../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../../common/style/styled";
import historyImg from "./img/historyImg.png";

// const historyData = [
//   { title: "창업", text: ["2021년 5월 13일"] },
//   {
//     title: "기초연구 및 실험",
//     text: ["21.1월 ~ 2022.7월"],
//   },
//   {
//     title: "소재지",
//     text: [
//       "본점 : 경북 경산시 강변서로 53길 23, 2층",
//       "서울지사 : 서울 송파구 백제고분로 67 (소프트웨어팀)",
//       "공장 : 경북 경산시 진량읍 일연로 502-13",
//       "실증사업지 : 옥산지하공영주차장(강변서로 51길 49)",
//     ],
//   },
//   {
//     title: "실증사업 신청 및 승인",
//     text: [
//       "22.2월 국토교통부에 신청",
//       "22.8월 국토교통부에서 승인(5억원 지원)",
//     ],
//   },
//   { title: "자본금", text: ["1,290백만원(주식발행초과급 1,071백만원 포함)"] },
//   {
//     title: "실증사업 진행 경과",
//     text: [
//       `23. 5월 충전기 10대/주차면 40면의 자동충전시스템
//                건설 및 실험 완료
//        7.7 국토교통부, 산업부 등의 최종점검 완료
//        7.19 ~ 24, 주요 잠재 고객 초청 시연회
//        9.19 ~ 10.28. 무료충전 서비스 진행 완료`,
//       "24.4월 이후 유료화 추진",
//     ],
//   },
//   {
//     title: "주요연혁",
//     text: [
//       "- 기업부설연구소 설립 : 2021년 11월 11일",
//       "- 벤처기업 인증 : 2021년 12월 29일",
//       "- 스마트실증사업 승인 : 2022년 8월 31일",
//       "- 특허 등록 4건 / 심사 중 2건 / PCT 출원 완료 4건",
//       "- 신규 추가 특허출원 2건(2023년)",
//     ],
//   },
// ];
const History = () => {
  return (
    <>
      <MainLayout>
        <HistoryContainer>
          {" "}
          <HistoryTitle>
            <h1>회사연혁</h1>
            {/* <p>지금 FEC가 나아온길</p> */}
            {/* <p>더 높은 곳을 향해 나아가는 (주)FEC가 되겠습니다.</p>ㄴ */}
          </HistoryTitle>
          <CompanyHistory>
            <img src={historyImg} alt="historyImg" />
            {/* {historyData.map((item) => {
            return (
              <CompanyHistoryItem>
                <div className="arrow">
                  <span class="material-symbols-outlined">double_arrow</span>
                </div>
                <div className="history-text">
                  <h2> {item.title}</h2>
                  {item.text.map((item) => {
                    return <pre>{item}</pre>;
                  })}
                </div>
              </CompanyHistoryItem>
            );
          })} */}
          </CompanyHistory>
        </HistoryContainer>
      </MainLayout>
      <Footer />
    </>
  );
};

export default History;
