import React from "react";
import Footer from "../../Footer/Footer";
import {
  GreetingTitle,
  Introduction,
} from "../../CompanyIntroduction/Greeeting/style";
import { CompanySkillContainer, SillBox, Wrapper } from "./style";
import DetailNumber from "./DetailNumber/DetailNumber";
import SubMenuCard from "../../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../../common/style/styled";
import SubTitle from "../../common/SubTitle/SubTitle";

const BusinessIntroduction = () => {
  return (
    <>
      <MainLayout>
        <SubTitle title="PRODUCTION" strong="Why?" text="(주)FEC 인가?" />
        <CompanySkillContainer>
          <SillBox>
            <div className="skill-container">
              <div className="line"></div>
              <p className="skill-container__title">고객을 생각하는</p>
            </div>
            <div className="skill-sub__intro">항상 열려있는 CS 센터</div>
          </SillBox>
          <SillBox>
            <div className="skill-container">
              <div className="line"></div>
              <p className="skill-container__title">알고리즘</p>
            </div>
            <div className="skill-sub__intro">경로형성 프로그램</div>
          </SillBox>
          <SillBox>
            <div className="skill-container">
              <div className="line"></div>
              <p className="skill-container__title">어렵지 않은</p>
            </div>
            <div className="skill-sub__intro">누구나 쉬운 충전</div>
          </SillBox>
          <SillBox>
            <div className="skill-container">
              <div className="line"></div>
              <p className="skill-container__title">모바일 QR신청</p>
            </div>
            <div className="skill-sub__intro">간단한 충전 신청</div>
          </SillBox>
          <SillBox>
            <div className="skill-container">
              <div className="line"></div>
              <p className="skill-container__title">믿음이 가는</p>
            </div>
            <div className="skill-sub__intro">투명한 유지보수관리</div>
          </SillBox>
        </CompanySkillContainer>

        {/* 사업내용 순서 */}
        {content.map((item, idx) => (
          <DetailNumber
            key={idx}
            number={item.number}
            title={item.title}
            description={item.description}
          />
        ))}
      </MainLayout>
      <Footer />
    </>
  );
};

export default BusinessIntroduction;

const content = [
  {
    number: 1,
    title: "주차장에 커넥터와 전선, 스위치 설치",
    description:
      "평소에 커넥터와 충전기는 연결되어있지 않지만, 차량을 충전할 때 경로 형성 스위치가 커넥터에서 충전기까지 전선을 통해 연결되도록 작동합니다.",
  },
  {
    number: 2,
    title: "㈜ FEC만의 고유 기술, 경로 형성 스위치",
    description:
      "충전소를 관리하는 서버가 충전 신청에 맞춰 경로 형성 정보를 스위치에 전달하면 이에 맞춰 릴레이를 작동시켜 경로를 형성합니다.",
  },
  {
    number: 3,
    title: "자동으로 충전 경로를 형성하는 알고리즘",
    description:
      "전기차 사용자가 충전 신청을 하면 충전 신청 내역에 따라 알고리즘을 통해 경로를 형성하여 충전을 진행합니다. 모든 충전기가 사용 중이면 충전 대기열에 등록합니다. 이후 앞서 충전하던 차량이 충전 완료되면 대기열에 따라 다음 차량을 자동으로 충전합니다.",
  },
];
