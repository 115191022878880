import { styled } from "styled-components";

export const CompanySkillContainer = styled.div`

    display:flex;
    justify-content: space-between;
    margin-bottom:30px;
`
export const SillBox = styled.div`
    width:200px;
    height:165px;
    border:2px solid #e1e1e1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    .skill-container{    
        width:100%;
        position: relative;
        display:flex;
        justify-content:center  ;
        align-items:center;       
        .skill-container__title{
            color:#888;
        }
        .line{
            position: absolute;
            left: 0;  // 이 값을 조정하여 원하는 위치에 배치할 수 있습니다.
            width:4px;
            height:13px;
            background-color: #dddd;
        }
    }
    .skill-sub__intro{
        width:100%;
        display:flex;
        font-size:18px;
        font-weight:bold;
        justify-content: center;
        align-items:center;
        margin-top:11px;
    }
`
