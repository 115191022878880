import { styled } from "styled-components";
import { TechnicalLayout } from "../style/Technical.style";

export const TechnicalIntroduceLayout = styled(TechnicalLayout)`
  height: 500px;
`;
export const TechnicalIntroduceTitle = styled.h1`
  margin-top: 20px;
  font-size: 35px;
  font-weight: 700;
`;
export const TechnicalIntroduceDownloadBox = styled.div`
  margin-top: 20px;
  width: 100%;
  /* height: 20vh; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  a:nth-of-type(1) {
    margin-right: 30px;
  }
  @media screen and (max-width: 1200px) {
    align-items: flex-start;
    flex-direction: column;
    a:nth-of-type(1) {
      margin-right: 0;
      margin: 30px 0;
    }
  }
`;

export const TechnicalIntroduceDownloadBtn = styled.button`
  width: 300px;
  font-weight: 600;
  white-space: nowrap;
  color: white;
  font-size: 25px;
  padding: 30px 20px;
  /* width: 200px; */
  /* height: 100px; */
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backColor};
`;
