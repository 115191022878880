import { styled } from "styled-components";
import { TechnicalLayout } from "../style/Technical.style";

export const PatentLayout = styled(TechnicalLayout)``;

export const PatentTitle = styled.h1`
  margin: 20px 0;
  font-size: 35px;
  font-weight: 600;
`;

export const PatentItemBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PatentItem = styled.div`
  width: 100%;
  max-width: 300px;
  height: 350px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    height: 250px;
  }
`;

export const ForeignPatent = styled.div`
  width: 100%;
  height: 500px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    height: 250px;
  }
`;
