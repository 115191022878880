import React from "react";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";
import { InquiryContainer, Text } from "./style";

const Inquiry = () => {
  return (
    <>
      <MainLayout>
        <InquiryContainer>
          <Text>서비스 준비 중 입니다. 조금만 기다려주세요.</Text>
        </InquiryContainer>
      </MainLayout>
      <Footer />
    </>
  );
};

export default Inquiry;
