import { styled } from "styled-components"

// AuthInput
export const Input = styled.input`
    width:450px;
    height:50px;
    
`

// AuthLabel
export const Label = styled.label`
    margin-bottom:10px;
    color:#3f4789;
    font-weight:600;
`
