import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";
import { Address, AddressLayout, Map } from "./style";
const { kakao } = window;

const LocationGuide = () => {
  useEffect(() => {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(35.83009280880806, 128.728312970346648),
      level: 3,
      draggable: false,
    };
    const map = new kakao.maps.Map(container, options);

    const positions = [
      {
        content: `<div style="padding : 15px; font-size : 25px;">실증사업지 (옥산공영주차장)</div>`,
        latlng: new kakao.maps.LatLng(35.82939280880806, 128.726312970346648),
      },
      {
        content: `<div style="padding : 15px; font-size : 25px; ">FEC 본사</div>`,
        latlng: new kakao.maps.LatLng(35.830162808, 128.72979397035),
      },
    ];
    for (let i = 0; i < positions.length; ++i) {
      const marker = new kakao.maps.Marker({
        map: map,
        position: positions[i].latlng,
      });
      //   marker.setMap(map);

      //   const iwTag = `<div style = "padding : 10px;">실증사업지 (옥산공영주차장)</div>`;
      //   const iwPosition = new kakao.maps.LatLng(
      //     35.82939280880806,
      //     128.726312970346648
      //   );

      const infoWindow = new kakao.maps.InfoWindow({
        position: positions[i].latlng,
        content: positions[i].content,
      });

      infoWindow.open(map, marker);

      const containerTwo = document.getElementById("map-2");
      const optionsTwo = {
        center: new kakao.maps.LatLng(37.5108066486908, 127.07891435183716),
        level: 3,
        draggable: false,
      };
      const mapTwo = new kakao.maps.Map(containerTwo, optionsTwo);

      const positionsTwo = {
        content: `<div style="padding : 15px; font-size : 25px;">서울지점</div>`,
        latlng: new kakao.maps.LatLng(37.5108066486908, 127.07891435183716),
      };

      const markerTwo = new kakao.maps.Marker({
        map: mapTwo,
        position: positionsTwo.latlng,
      });
      //   marker.setMap(map);

      //   const iwTag = `<div style = "padding : 10px;">실증사업지 (옥산공영주차장)</div>`;
      //   const iwPosition = new kakao.maps.LatLng(
      //     35.82939280880806,
      //     128.726312970346648
      //   );

      const infoWindowTwo = new kakao.maps.InfoWindow({
        position: positionsTwo.latlng,
        content: positionsTwo.content,
      });

      infoWindowTwo.open(mapTwo, markerTwo);
    }

    const containerOne = document.getElementById("map-1");
    const optionsOne = {
      center: new kakao.maps.LatLng(35.82939280880806, 128.726312970346648),
      level: 3,
      // draggable: false,
    };
    const mapOne = new kakao.maps.Map(containerOne, optionsOne);

    const positionsOne = {
      content: `<div style="padding : 15px; font-size : 25px;">개발중</div>`,
      latlng: new kakao.maps.LatLng(35.82939280880806, 128.726312970346648),
    };

    const markerOne = new kakao.maps.Marker({
      map: mapOne,
      position: positionsOne.latlng,
    });
    //   marker.setMap(map);

    //   const iwTag = `<div style = "padding : 10px;">실증사업지 (옥산공영주차장)</div>`;
    //   const iwPosition = new kakao.maps.LatLng(
    //     35.82939280880806,
    //     128.726312970346648
    //   );

    const infoWindowOne = new kakao.maps.InfoWindow({
      position: positionsOne.latlng,
      content: positionsOne.content,
    });

    infoWindowOne.open(mapOne, markerOne);
  }, []);

  return (
    <>
      <MainLayout>
        <AddressLayout>
          <Address>
            <h3>사업장 위치</h3>
            <div>경상북도 경산시 강변서로 51길 49</div>
            <div className="line"></div>
          </Address>
          <Map id="map" />
          <Address>
            <h3>공장</h3>
            <div>경북 경산시 진량읍 일연로 502-13</div>
            <div className="line"></div>
          </Address>
          <Map id="map-1" />
          <Address>
            <h3>서울지점</h3>
            <div>
              서울특별시 송파구 백제고분로 67, 607호 (잠실동, 위너스오피스텔2)
            </div>
            <div className="line"></div>
          </Address>
          <Map id="map-2" />
        </AddressLayout>
      </MainLayout>
      <Footer />
    </>
  );
};

export default LocationGuide;
