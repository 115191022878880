import { Content } from "../common/style";
import AuthInfo from "./AuthInfo/AuthInfo";
import Footer from "../../Footer/Footer";
import SubMenuCard from "../../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../../common/style/styled";
import { Title } from "./style";
import CertificationNumber from "./Certification/CertificationNumber";
import { useState } from "react";
import { useSelector } from "react-redux";
const AuthSignup = () => {
  const [certification, setCertification] = useState(false);

  const { userInfo } = useSelector((state) => state.userInfo);

  console.log("heheheh", userInfo);
  // user 정보가 없으면 홈으로 이동
  if (!userInfo) {
    alert("준비단계 중 입니다.");
    window.location.href = "/";
  }
  return (
    <>
      <MainLayout>
        <Content>
          <Title>회원가입</Title>
          {
            certification ? (
              // AuthInfo ->인증번호 받은 후 정보입력
              <AuthInfo />
            ) : (
              <CertificationNumber certification={setCertification} />
            )
            // CertificationNumber -> 폰번호, 인증번호 입력
          }
        </Content>
      </MainLayout>
      <Footer />
    </>
  );
};

export default AuthSignup;
