import { styled } from "styled-components";

export const MainBanner = styled.div`
  height: 716px;
  background-image: url("/img/mainBanner/main_banner_01.jpg");
`;

export const MainLayout = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackImg = styled.div`
  width: 100%;
  height: 500px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
