import {
  TechnicalIntroduceDownloadBox,
  TechnicalIntroduceDownloadBtn,
  TechnicalIntroduceLayout,
  TechnicalIntroduceTitle,
} from "./TechnicalIntroduce.style";
import introduceppt from "./techIntroduce.pptx";
import introduceppt2 from "./techIntroduce2.pptx";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";

const TechnicalIntroduce = () => {
  return (
    <>
      <MainLayout>
        <TechnicalIntroduceLayout>
          <TechnicalIntroduceTitle>기술 소개 자료</TechnicalIntroduceTitle>
          <TechnicalIntroduceDownloadBox>
            <a href={introduceppt} download={""}>
              <TechnicalIntroduceDownloadBtn backColor="#6478ff">
                기업 소개 자료 다운로드
              </TechnicalIntroduceDownloadBtn>
            </a>
            <a href={introduceppt2} download={""}>
              <TechnicalIntroduceDownloadBtn backColor="#6478ff">
                기업 소개 자료2 다운로드
              </TechnicalIntroduceDownloadBtn>
            </a>
          </TechnicalIntroduceDownloadBox>
        </TechnicalIntroduceLayout>
      </MainLayout>
      <Footer />
    </>
  );
};

export default TechnicalIntroduce;
