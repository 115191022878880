import { styled } from "styled-components";

export const Container = styled.div`
    max-width:1080px;
    margin:0 auto;
`
export const NoticeContainer = styled.div`
    border-top:1px solid #555;
    width:100%;
    height:100%;
    margin-top:105px;
`
export const PostInfo = styled.div`
    width:100%;
    display:flex;
    height:50px;
    justify-content: space-between;
    font-size:15px;
    border-bottom: 1px solid #e1e1e1;
    display:flex;
    align-items: center;
    box-sizing: border-box;
    padding:0px 37px;
    font-weight:bold;
    .post-info__author{
        span:nth-child(1){
            margin-right:64px;
        }
    }

`
export const Post = styled.div`
    height:50px;
    border-bottom: 1px solid #e1e1e1;
    font-size:15px;
    border-bottom: 1px solid #e1e1e1;
`
export const PostContainer = styled.div`
    width:100%;
    height:100%;
    display:flex;
    padding-left:35px;
    align-items:center;
    box-sizing: border-box;
    justify-content: space-between;
    &:hover{
        background:#e8e8e8;
    }
    .post-info__title{
        width:100%;
        margin-left:44px;
        font-weight:600;
    }
    .post-info__author{
        display:flex;
        width:100%;
        justify-content: flex-end;
        margin-right:0;
        .post-date__time{
            padding-right:8px;
        }
        span:nth-child(1){
            margin-right:50px;
        }
    }
`
export const Text = styled.p`
    color:rgba(0,0,0,0.5);
    text-align:center;
`