import { Explanation, Introduction } from "./style";

const SubTitle = ({ title, strong, text, text02 }) => {
    return (
        <Introduction>
            <Explanation>
                <h4 className='main-title'>{title}</h4>
                <div id='line'></div>
                <p className='title title-text'><strong>{strong}</strong>{text}</p>
                <p className='sub-title'>{text02}</p>
            </Explanation>
        </Introduction>
    )
}

export default SubTitle;


