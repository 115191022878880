import {
  CardContainer,
  MenuCard,
} from "../../../CompanyIntroduction/Greeeting/style";
import Footer from "../../../Footer/Footer";
import SubMenuCard from "../../../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../../../common/style/styled";
import {
  DetailContainer,
  DetailImage,
  DetailText,
  FieldContainer,
  FieldTitle,
  ListBtn,
  WriterContainer,
} from "./style";

const FieldDetail = () => {
  return (
    <>
      <SubMenuCard url01="/service/:id" menutext01="서비스 분야" />
      <MainLayout>
        <FieldContainer>
          <FieldTitle>
            <h2>충전기 설치</h2>
          </FieldTitle>
          <WriterContainer>
            <div className="writer-container">
              <p>작성자 |</p>
              <p> 최고관리자</p>
            </div>
            <div className="writer-container">
              <p>등록일 |</p>
              <p> 23-11-11</p>
            </div>
          </WriterContainer>
          <DetailContainer>
            <DetailImage />
            <DetailText>
              <p>충전기설치</p>
            </DetailText>
            <ListBtn>목록</ListBtn>
          </DetailContainer>
        </FieldContainer>
      </MainLayout>
      <Footer />
    </>
  );
};

export default FieldDetail;
