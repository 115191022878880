import { styled } from "styled-components";

export const PopupModalBack = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupModalBox = styled.div`
  margin: 0 20px;
  position: relative;
  background-color: white;
  z-index: 100;
  /* padding: 10px; */
  width: 500px;
  border: none;
  border-radius: 20px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
  }
`;

export const PopupModalCloseBox = styled.div`
  /* padding: 5px; */
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 20px 20px 20px;
  button {
    margin-right: 20px;
  }
`;
export const PopupModalCloseBtn = styled.button`
  cursor: pointer;
  /* width: 100spx; */
  /* padding: 10px 15px; */
  /* height: 50px; */
  border: none;
  /* background: #6478ff; */
  /* border-radius: 20px; */
  background: white;
  color: black;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
