import React from "react";
import { CardContainer, SubCard, StyledLink } from "./style";
import { Outlet, useLocation } from "react-router-dom";

const SubMenuCard = ({
  menutext01,
  menutext02,
  menutext03,
  menutext04,
  url01,
  url02,
  url03,
  url04,
}) => {
  const router = useLocation();

  return (
    <>
      <SubCard>
        <CardContainer>
          <div>
            <StyledLink active={router.pathname === url01} to={url01}>
              {menutext01}
            </StyledLink>
          </div>
          {menutext02 && (
            <div>
              <StyledLink active={router.pathname === url02} to={url02}>
                {menutext02}
              </StyledLink>
            </div>
          )}
          {menutext03 && (
            <div>
              <StyledLink active={router.pathname === url03} to={url03}>
                {menutext03}
              </StyledLink>
            </div>
          )}
          {menutext04 && (
            <div>
              <StyledLink active={router.pathname === url04} to={url04}>
                {menutext04}
              </StyledLink>
            </div>
          )}
        </CardContainer>
      </SubCard>
      <Outlet />
    </>
  );
};

export default SubMenuCard;
