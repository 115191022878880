import { styled } from "styled-components";

export const Field = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content: space-between;
`
export const Text = styled.p`
    color:rgba(0,0,0,0.5);
    text-align:center;
`
export const FieldContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    div{
        margin-top:10px;
        font-size:18px;
        font-weight:600;
    }
`
export const Image = styled.div`
    width:250px;;
    height: 177px;  
    border:1px solid black;
    margin:0 auto;
    div{
        width:90%;
        height:100%;
        background-color:blue;
        margin:0 auto;
    }
`
