import React, { useEffect, useState } from "react";
import { MainLayout } from "../common/style/styled";
import Footer from "../Footer/Footer";
import { AddressContainer, AddressListBox, Address, Title } from "./style";
import KaKaoMap from "../common/KaKaoMap/KaKaoMap";
const ChargeAddress = () => {
  // 배열로 상태를 관리합니다. 인덱스는 주소를 구분합니다.
  const [addressClick, setAddressClick] = useState([]);
  const [chargeAddress, setChargeAddress] = useState([]);
  const onAddressClick = (index) => {
    console.log("address click");
    // 특정 인덱스의 상태만 변경합니다.
    setAddressClick((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await fetch("https://fecbe.xyz/charge/location/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        if (response.status === 200) {
          console.log(responseData);
          return setChargeAddress(responseData);
        }
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  console.log("address", Object.values(chargeAddress));
  // 충전 능력 및 충전 주차면

  // 충전기 50KW 10기 / 충전가능 주차면 40면
  // 현재 충전 및 주차상황 (준비 중)
  // 충전 가능 충전기 (10/10)
  const arrChargeAddress = Object.values(chargeAddress);
  const chargeMap = arrChargeAddress.map((item) => item);
  console.log("map", chargeMap.cneterName);
  return (
    <>
      <MainLayout>
        <Title>CHARGE ADDRESS</Title>
        {arrChargeAddress.map((item) => (
          <>
            <Address>
              <div className="charge-center">{item.centerName}</div>
              <div className="charge-address">{item.address}</div>
              <div>
                {item.performance} / 충전가능 주차면{item.parkingTotal}
              </div>
              <div>현재 충전 및 주차상황 (준비 중)</div>
              <div>
                {" "}
                충전 가능 충전기({item.chargerNow}/{item.chargerTotal})
              </div>
            </Address>
            <KaKaoMap width="100%" height="350px" />
          </>
        ))}
        <Address></Address>
        {/* {arrChargeAddress.map((item, idx) => {
                    return (
                        <>
                            <AddressContainer key={idx}>
                                <AddressListBox onClick={() => onAddressClick(idx)}>
                                    <h3>{item.centerName}</h3>
                                    <address>({item.address})</address>
                                    <div className="address-icon">
                                        <img src='/img/addressIcon.svg' />
                                    </div>
                                </AddressListBox>
                                {addressClick[idx] &&
                                    <div >
                                        <p style={{ padding: '28px' }}>
                                            {item.performance} / 충전가능 주차면 {item.parkingTotal}
                                        </p>
                                        <p style={{ padding: '28px' }}>
                                            충전 가능 충전기({item.chargerNow}/{item.chargerTotal})
                                        </p>
                                        <KaKaoMap width="100%" height="350px" />
                                    </div>
                                }
                            </AddressContainer>
                        </>

                    )
                })} */}
        {/* {addresses.map((address, index) => (
                    <AddressContainer key={index}>

                        <AddressListBox onClick={() => onAddressClick(index)}>
                            <h3>경산 옥산 충전소</h3>
                            <address>{address}</address>
                            <div className="address-icon">
                                <img src="/img/addressIcon.svg" />
                            </div>
                        </AddressListBox>
                        {addressClick[index] &&
                            <div >
                                <p style={{ padding: '28px' }}>충전 가능 충전기 (10/10)</p>
                                <KaKaoMap width="100%" height="350px" />
                            </div>
                        }
                    </AddressContainer>
                ))} */}
      </MainLayout>
      <Footer />
    </>
  );
};

export default ChargeAddress;
