import { styled } from "styled-components";
import { CompanyIntroductionLayout } from "../common/style";

export const AddressLayout = styled(CompanyIntroductionLayout)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Address = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  font-size: 17px;
  h3 {
    font-size: 32px;
    padding-bottom: 10px;
  }
  .line {
    width: 22px;
    margin: 0 auto;
    margin-top: 30px;
    border-bottom: 1px solid #ddd;
  }
`;
export const Map = styled.div`
  margin-bottom: 50px;
  width: 100%;
  height: 500px;
  border-radius: 8px;
  @media screen and (max-width: 1024px) {
    height: 200px;
  }
`;
