import React from "react";
import { CompanyContent, ContentItem } from "./style";
import { ContentText } from "../Information/style";

const CompanyContentIndex = () => {
  const handlerLink = (url) => {
    window.location = url;
  };
  return (
    <CompanyContent>
      <ContentItem>
        <span>(주)FEC의 전기차</span>
        <span>자동 충전 시스템은</span>
      </ContentItem>
      {contentData.map((item, index) => (
        <ContentItem key={index}>
          <div className="item-number">
            <span>{item.id}</span>
          </div>
          <div className="company-sub__content">
            {item.subText.map((text, idx) => (
              <span key={idx}>{text}</span>
            ))}
          </div>
        </ContentItem>
      ))}
    </CompanyContent>
  );
};

export default CompanyContentIndex;

const contentData = [
  {
    id: "01",

    subText: [
      "전기차 충전사업자(CPO, Charging Point Operater)에게 보다 높은 이윤을 제공합니다.",
      "왜냐하면 투자비는 낮게, 충전기의 가동시간은 높여주기 때문입니다.",
    ],
  },
  {
    id: "02",

    subText: [
      "국가와 한국전력 같은 전기생산공급자의 근심을 줄여 줍니다.",
      "왜냐하면 충전수요가 동일해도 계약전력(대기전력)을 50%이상 줄여주어 피크부하를 크게 낮춰주기 떄문입니다.",
    ],
  },
  {
    id: "03",

    subText: [
      "(주)FEC와 제휴한 충전기제조업체에 높은 매출 증가와 수익을 보장하고 공유할 것입니다.",
      "강하고 촘촘한 특허가 세계 시장을 커버하고 있습니다.",
    ],
  },
  {
    id: "04",

    subText: [
      "충전하는 전기차 차주에게 충전 중에도 무엇이든 할 수 있는 자유를 줍니다.",
    ],
  },
];
