import { styled } from "styled-components";
import { Link } from "react-router-dom";

export const SubCard = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  position: relative;
  /* overflow: hidden; */
`;
export const CardContainer = styled.div`
  max-width: 600px;
  width: 100%;

  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 32px;
  font-weight: bold;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
`;

export const StyledLink = styled(Link)`
  color: ${(props) => (props.active ? "#3f4789" : "#000")};
`;
