import { styled } from "styled-components";

export const ChangeLanguageBox = styled.div`
  border-radius: 20px;
  width: 80px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  right: 0;
  .lan-icon {
    margin-right: 10px;
    span {
      font-size: 23px;
      color: white;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const ChangeLanguageItem = styled.div`
  cursor: pointer;
  font-size: 20px;
  color: ${(props) => (props.isLan ? "#FFCD00" : "white")};
  margin-right: 10px;
  /* text-decoration-line: underline; */
`;

export const ChangeLanguageCurrentBar = styled.div`
  margin-top: 5px;
  width: 25px;
  height: 5px;
  border-radius: 20px;
  background: #ffcd00;
  transform: translate(0);
  transition: 0.1s all linear;
`;
