import React, { useState } from "react";
import AuthInput from "../../common/AuthInput/AuthInput";
import { InputContainer } from "../../common/style";
import { useForm } from "react-hook-form";
import { AuthSignupApi } from "../../../../api/api";
import AuthButton from "../../common/AuthButton/AuthButton";
import AuthForm from "../../common/AuthForm/AuthForm";
import AuthErrorMsg from "../../common/AuthErrorMsg/AuthErrorMsg";


const AuthInfo = () => {
    const { register, handleSubmit ,formState:{errors}, setError} = useForm();
    const { isLoading, isError, postData } = AuthSignupApi();
    const [passwordMismatch, setPasswordMismatch] = useState(''); // 비밀번호 비밀번호 확인 중복체크

    const handleAuthInfoSubmit = async (data) => {
        const { password, passwordCheck } = data;

        const response = await postData(
            process.env.REACT_APP_API_USER_ACCOUNT,
            data,
        )
        if(response){
            // 회원가입이 성공적으로 login 페이지로 이동
            window.location = '/auth/login'; 
        }
        if (password !== passwordCheck) {
            setPasswordMismatch('비밀번호가 일치하지 않습니다.')
        }
    }
    return (
        <AuthForm onSubmit={handleSubmit(handleAuthInfoSubmit)}>
            <InputContainer>
                <AuthInput
                    type='text'
                    text="이름"
                    placeholder="이름"
                    register={register('name', {
                        required: true
                    })}
                />
            </InputContainer>
            <InputContainer>
                <AuthInput
                    type='number'
                    text="휴대폰번호"
                    placeholder="휴대폰번호"
                    register={register('phone', {
                        required: true,
                        minLength: {
                            value: 11,
                            message: '휴대폰번호는 11자리 입니다.'
                        }
                    })}
                />
            </InputContainer>
            <InputContainer>
                <AuthInput
                    type='email'
                    text="이메일"
                    placeholder="이메일"
                    register={register('email', {
                        required: '이메일을 입력해주세요.'
                    })}
                />
            </InputContainer>
            <InputContainer>
                <AuthInput
                    type='text'
                    text="주소"
                    placeholder="주소"
                    register={register('home_addr', {
                        required: true
                    })}
                />
            </InputContainer>
            <InputContainer>
                <AuthInput
                    type='password'
                    text="비밀번호"
                    placeholder="비밀번호"
                    register={register('password', {
                        required: true,
                        pattern: {
                            value: /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,16}$/,
                            message: '비밀번호에는 최소한 2개 이상의 특수 문자가 포함되어야 합니다.'
                        }
                    })}
                />
            </InputContainer>
            <InputContainer>
                <AuthInput
                    type='password'
                    text="비밀번호 확인"
                    placeholder="비밀번호 확인"
                    register={register("passwordCheck", {
                        required: true
                    })}
                />

                {passwordMismatch ? passwordMismatch : null}
                {errors.email && <AuthErrorMsg>{errors.email.message}</AuthErrorMsg>}
                {errors.password && <AuthErrorMsg>{errors.password.message}</AuthErrorMsg>}
            </InputContainer>
            <AuthButton
                isLoading={isLoading}
                text='회원가입'
                type='submit'
            />
        </AuthForm>
    )
}

export default AuthInfo;