import { styled } from "styled-components";
import { CompanyIntroductionLayout } from "../common/style";

export const HistoryContainer = styled(CompanyIntroductionLayout)`
  display: flex;
  flex-direction: column;
`;
export const HistoryTitle = styled.h1`
  h1 {
    font-size: 35px;
    font-weight: 600;
  }
`;
export const CompanyHistory = styled.div`
  width: 100%;
  height: 450px;
  margin: 30px 0;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    height: 250px;
  }
`;
