import React from "react";
import {
  ContentList,
  ContentUl,
  NavigateBox,
  NavigateItem,
  NavigateList,
  NavigateSubBox,
  NavigateSubList,
  NavigateSubListItem,
  SubContent,
  SubContentList,
  TechnicalVideo,
} from "./style";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { content } from "../content";
const Navigate = ({ userInfo }) => {
  // 회원 로그아웃
  const handlerTokenRemove = () => {
    // cookie에 저장되어있는 token 삭제
    Cookies.remove("token");
    // token remove가 되면 로그아웃이 되면서 홈으로 이동
    window.location = "/";
  };
  return (
    <NavigateBox>
      {content.map((item, idx) => (
        <NavigateItem key={idx} idx={idx}>
          <Link to={item.url}>{item.title}</Link>
        </NavigateItem>
      ))}
      <NavigateSubBox>
        {/* <span>hello</span> */}
        <div style={{ width: "100%", maxWidth: "1400px", display: "flex" }}>
          <TechnicalVideo>hello</TechnicalVideo>
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            {content.map((item) => {
              return (
                <NavigateSubList>
                  {item.subContent.map((item) => {
                    return (
                      <NavigateSubListItem>
                        <Link to={item.url}>{item.name}</Link>
                      </NavigateSubListItem>
                    );
                  })}
                </NavigateSubList>
              );
            })}
          </div>
        </div>
      </NavigateSubBox>
    </NavigateBox>
  );
};

export default Navigate;
