import React from "react";
import {
  MobileImage,
  ImageTitleContainer,
  AdvantagesContainer,
  AdvantagesTitle,
  AdvantagesCardContainer,
} from "./style";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";
import SubTitle from "../../common/SubTitle/SubTitle";
import AdvantagesCard from "./AdvantagesCard/AdvantagesCard";
import { Link } from "react-router-dom";
const Mobile = () => {
  return (
    <>
      <MainLayout>
        <SubTitle
          title="MOBILEWEB"
          strong="모바일QR"
          text="신청으로 누구나 간단하게!"
          text02={
            <>
              <span>회원, 비회원 상관없이 누구나 빠르게 모바일로 </span>
              <br /> 충전할 수 있습니다.
            </>
          }
        />

        <MobileImage>
          <ImageTitleContainer>
            <p>FEC 자유 충전 시스템은 키오스크 서비스도 제공하지만</p>
            <p>좀 더 신속한 이용을 위해 고민했습니다.</p>
            <div className="big-text">
              <p>
                그래서 FEC 자유 충전 시스템의 모든 커넥터에는 QR 코드가 부착되어
              </p>
              <p>모바일로 신속하게 편리하게 충전할 수 있습니다.</p>
            </div>
          </ImageTitleContainer>
        </MobileImage>

        <AdvantagesContainer>
          <AdvantagesTitle>
            <h3 className="advantages-title">
              모바일웹의 <span>장점</span>
            </h3>
            <span className="advantages-text">
              전기차 사용자가 온라인 충전 및 결제 시스템과 연계하여
              <br /> 더 간편하고 신속하게 전기차 충전 신청할 수 있습니다.
            </span>
            <div className="mobile-address">
              <span>
                모바일 접속:{" "}
                <Link to="https://fecmobile.co.kr/">
                  https://fecmobile.co.kr/
                </Link>
              </span>
            </div>
          </AdvantagesTitle>
          <AdvantagesCardContainer>
            {content.map((item, idx) => {
              return (
                <AdvantagesCard
                  key={idx}
                  imgUrl={item.imgUrl}
                  title={item.title}
                  subTitle={item.subTitle}
                  description={item.description}
                />
              );
            })}
          </AdvantagesCardContainer>
        </AdvantagesContainer>
      </MainLayout>
      <Footer />
    </>
  );
};

export default Mobile;

const content = [
  {
    title: "Compact",
    imgUrl: "/img/p11_icon01.png",
    subTitle: "간편한",
    description: "QR 코드를 촬영 후 인증번호만 입력하면 끝",
  },
  {
    title: "Simple",
    imgUrl: "/img/p11_icon02.png",

    subTitle: "심플",

    description:
      "쉽고 간편하게 구성된 내용 위주로 사용자 인터페이스를 가지고 있습니다.",
  },
  {
    title: "Accessibillity",
    imgUrl: "/img/p11_icon03.png",
    subTitle: "접근성",
    description: "누가나 익숙한 휴대폰으로 접근",
  },
];
