import {
  ForeignPatent,
  PatentItem,
  PatentItemBox,
  PatentLayout,
  PatentTitle,
} from "./Patent.style";
import patent1 from "./patent1.png";
import patent2 from "./patent2.png";
import patent3 from "./patent3.png";
import patent4 from "./patent4.png";
import patent5 from "./patent5.png";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";

const patentData = [patent1, patent2, patent3, patent4];
const Patent = () => {
  return (
    <>
      <MainLayout>
        <PatentLayout>
          <PatentTitle>특허 등록증</PatentTitle>
          <PatentItemBox>
            {patentData.map((item) => {
              return (
                <PatentItem>
                  <img src={item} alt="photo" />
                </PatentItem>
              );
            })}
          </PatentItemBox>
          <PatentTitle>국내외 특허 출원 및 등록 현황</PatentTitle>
          <ForeignPatent>
            <img src={patent5} alt="photo" />
          </ForeignPatent>
        </PatentLayout>
      </MainLayout>
      <Footer />
    </>
  );
};

export default Patent;
