import React, { useEffect, useState } from "react";
import Header from "../Layout/Header/Header";
import IndexContent from "./IndexContent/IndexContent";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../store/userInfoReducer";
import { styled } from "styled-components";
import { MainLayout } from "../common/style/styled";
import { MainBackImg, MainTop } from "./IndexContent/style";
import PopupModal from "../common/Popup/PopupModal";

export const HomeLayout = styled(MainLayout)`
  flex-direction: column;
`;

const Home = () => {
  const dispatch = useDispatch();
  const { userInfo, loading, error } = useSelector((state) => state.userInfo);
  const [isPopupModal, setIsPopupModal] = useState(true);
  const date = new Date();
  const onclickPopupModalClose = () => {
    setIsPopupModal(false);
  };

  const onclickOneDayClose = () => {
    localStorage.setItem("modal", date.getDate());
    setIsPopupModal(false);
  };
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [fetchUserInfo]);
  console.log(Number(localStorage.getItem("modal")) === date.getDate());
  console.log(typeof localStorage.getItem("modal"));
  console.log(typeof date.getDate());
  return (
    <>
      {isPopupModal &&
        Number(localStorage.getItem("modal")) !== date.getDate() && (
          <PopupModal
            onclickCloseModal={onclickPopupModalClose}
            onclickOneDayClose={onclickOneDayClose}
          />
        )}
      <Header userInfo={userInfo} loading={loading} error={error} />
      <HomeLayout>
        <MainBackImg height={window.innerHeight - 500}>
          <img src="/img/mainBanner/main_banner_01.jpg" alt="backImg" />
        </MainBackImg>
        <IndexContent />
      </HomeLayout>
      <Footer />
    </>
  );
};

export default Home;
