import {
  MobileNavigateBack,
  MobileNavigateBox,
  MobileNavigateItem,
  MobileNavigateList,
  NavigateItem,
} from "./style";
import { content } from "../content";
const MobileNavigate = () => {
  return (
    <MobileNavigateBack>
      <MobileNavigateBox>
        <MobileNavigateList>
          {content.map((item) => {
            return (
              <MobileNavigateItem>
                <div style={{ width: "120px" }}>{item.title}</div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {item.subContent.map((item) => {
                    return <a href={item.url}>{item.name}</a>;
                  })}
                </div>
              </MobileNavigateItem>
            );
          })}
        </MobileNavigateList>
      </MobileNavigateBox>
    </MobileNavigateBack>
  );
};

export default MobileNavigate;
