import { styled } from "styled-components";

export const AddressContainer = styled.div`
    border-bottom:1px solid gray;

`
export const AddressListBox = styled.div`
    display:flex;
    padding:22px;
    font-size:18px;
    cursor: pointer;
    .address-icon{
       margin-left:auto;
    }
`
export const Title = styled.h2`
    text-align: center;
    font-size:18px;
    color:#0054a6;
    font-weight:bold;
    padding-bottom:10px;

`
export const Address = styled.div`
        font-size: 14px;
        letter-spacing: 2px;
        line-height:24px;
        font-weight:bold;
        font-size:17px;
        margin-bottom:50px;
        text-align:center;
        .charge-center{
            font-size:23px;
            margin:10px 0px;;
        }
        .charge-address{
            font-size:18px;
            margin-bottom:10px;
        }
`
export const ChargeAddressMap = styled.div`
    width:100%;
    height:100%;
    margin-top:28px;
`