import { useRef, useState } from "react";
import {
  ChangeLanguageBox,
  ChangeLanguageCurrentBar,
  ChangeLanguageItem,
} from "./ChangeLanguage.style";

const ChangeLanguage = () => {
  const [isLan, setIsLan] = useState("ko");
  const ref = useRef();
  return (
    <ChangeLanguageBox>
      <div>
        <ChangeLanguageItem
          onClick={() => {
            setIsLan("ko");
            ref.current.style.transform = "translateX(0)";
          }}
          isLan={isLan === "ko"}
        >
          Ko
        </ChangeLanguageItem>
        <ChangeLanguageItem
          onClick={() => {
            setIsLan("en");
            ref.current.style.transform = "translateX(33px)";
          }}
          isLan={isLan === "en"}
        >
          En
        </ChangeLanguageItem>
      </div>
      <ChangeLanguageCurrentBar ref={ref}></ChangeLanguageCurrentBar>
    </ChangeLanguageBox>
  );
};

export default ChangeLanguage;
