import { useSelector } from "react-redux";

export const useRequireLogin = () => {
    const userInfo = useSelector((state) => state.userInfo);
    if (!userInfo) {
        // alert으로 message 
        alert('로그인 후 이용이 가능합니다.')
        // 로그인 할 수 있도록 login으로 이동
        window.location = '/auth/login'
    }
};