import { styled } from "styled-components";

export const Main = styled.div`
  /* height: 320px; */
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
`;
export const MainBackImg = styled.div`
  width: 100%;
  height: ${(props) => `${props.height}px`};
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
