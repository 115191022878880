import { configureStore } from "@reduxjs/toolkit";
import userInfoReducer from "./userInfoReducer";
// configureStore을 통해 store을 만들어준다.
// configureStore은 여러개의 slice을 모아주는 역할

const store = configureStore({
    reducer: {
        userInfo: userInfoReducer,
    }
})

export default store;