import { styled } from "styled-components";

export const DetailContainer = styled.div`
    width:100%;
    margin-top:59px;
    margin-bottom:80px;
`
export const NumberTitleContainer = styled.div`
    display:flex;
    align-items: center;
    border-bottom:1px dashed #e1e1e1;
    padding-bottom:18px;
`
export const NumberTitle = styled.h2`
    padding-left:20px;
    font-size:19px;
    font-weight:600;
`
export const NumberBox = styled.div`
    width:50px;
    height:50px;
    border-radius : 50%;
    background-color: black;
    color:#ffff;
    display:flex;
    justify-content: center;
    align-items: center;
`
export const DetailTextContainer = styled.div`
    width:80%;
    padding-top:20px;
    font-size:16px;
    font-weight:500;
    color:#777;

    line-height: 28px;
`