import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthSignupApi = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState([]);

    const postData = async (url, data, message,) => {
        setIsLoading(true)
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include", //Cross-Origin 요청을 할 때 요청에 자동으로 쿠키와 인증 헤더를 포함하도록 설정하는 역할
                body: JSON.stringify(data)
            })
            const responseData = await response.json();
            if (response.status === 200) {
                setIsLoading(false);
                return responseData;
            } else if (response.status === 400) {
                setIsLoading(false);

                setIsError(message)
                return responseData;
            } else if (response.status === 404) {
                setIsLoading(false);
                return responseData;
            }
            else if (response.status === 409) {
                setIsLoading(false);
                setIsError(responseData);
                return responseData
            }
        } catch (error) {
            throw Error(error);
        }
        setIsLoading(false);
    }

    return { isLoading, isError, postData };
};


export const AuthLoginApi = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(null);

    const loginPost = async (url, data, location) => {
        setIsLoading(true);
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const responseData = await response.json();
            if (response.status === 200) {
                const token = responseData.user.token;
                setIsLoading(false);
                document.cookie = `token=${token}; path=/; secure;`;
                window.location = location;
                return responseData;
            } else if (response.status === 400) {
                setIsLoading(false);
                setIsError(responseData);
                return responseData;
            }

            else if (response.status === 409) {
                setIsLoading(false);
                setIsError(responseData);
                return responseData;
            } else if (response.status === 404) {
                setIsLoading(false);
                setIsError(responseData);
                return responseData;
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
    }
    return { isLoading, isError, loginPost }
}


// 수정 api 
export const AuthPatchchApi = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(null);

    const patchPost = async (url, data) => {
        setIsLoading(true);
        const token = Cookies.get('token')
        try {
            const response = await fetch(url, {
                method: "PATCH",
                headers: {
                    'Authorization': `token ${token}`,
                    'Content-Type': 'application/json',
                },
                // credentials: "include",
                body: JSON.stringify(data)
            })
            const responseData = await response.json();
            if (response.status === 200) {
                setIsLoading(false);
                window.location = '/auth/profile'
                return responseData;
            } else if (response.status === 403) {
                setIsLoading(false);
                setIsError(responseData);
                return responseData;
            }
            else if (response.status === 400) {
                setIsLoading(false);
                console.log('response 404', responseData)
                setIsError(responseData);
                return responseData;
            }

            else if (response.status === 409) {
                setIsLoading(false);

                setIsError(responseData);
                return responseData;
            } else if (response.status === 404) {
                setIsLoading(false);
                setIsError(responseData);
                return responseData;
            }
        } catch (error) {
            throw Error(error);
        }
        setIsLoading(false);
    }
    return { isLoading, isError, patchPost }
}