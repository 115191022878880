import React from "react";
import SmHeader from "../../Layout/SmHeader/SmHeader";
import { GreetingTitle, GreetingContainer, GreetingText } from "./style";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";
const greetingData = [
  <p>빠르게 발전하고 있는 미래의 편리한 전기차 시대!</p>,
  <p>(주)FEC가 여러분과 함께 만들어갑니다.</p>,
  <p>
    (주)FEC는 앞으로 다가오는 전기차 시대에 가장 적합한 전기차 충전 서비스를
    추구합니다.
  </p>,
  <p>FEC 충전시스템은</p>,
  <div className="text-list">
    <h3>첫째</h3>
    <div>
      <p>
        전기차 충전사업자(CPO, Charging Point Operater)에게 보다 높은 이윤을
        제공합니다.
      </p>
      <p>
        왜냐하면{" "}
        <span className="strong">
          투자비는 낮게, 충전기의 가동시간은 높여주기 떄문입니다.
        </span>
      </p>
    </div>
  </div>,
  <div className="text-list">
    <h3>둘째</h3>
    <div>
      <p>국가와 한국전력 같은 전기생산공급자의 근심을 줄여 줍니다.</p>
      <p>
        왜냐하면{" "}
        <span className="strong">
          충전수요가 동일해도 계약전력(대기전력)을 50%이상 줄여주어 피크부하를
          크게 낮춰주기
        </span>
        때문입니다.
      </p>
    </div>
  </div>,
  <div className="text-list">
    <h3>셋째</h3>
    <div>
      <p>
        (주)FEC와 제휴한 충전기제조업체에 높은 매출 증가와 수익을 보장하고
        공유할 것입니다.
      </p>
      <p>
        {" "}
        <span className="strong">강하고 촘촘한 특허가 세계 시장을 커버</span>
        하고 있습니다.
      </p>
    </div>
  </div>,
  <div className="text-list">
    <h3>넷째</h3>
    <div>
      <p>
        충전하는 전기차 차주에게 충전 중에도 무엇이든 할 수 있는 자유를 줍니다.
      </p>
    </div>
  </div>,
  <p>"FEC"는 Freedom of Electric Cars, 즉 전기차의 자유를 의미합니다.</p>,
  <p>(주)FEC는 미래의 전기차 충전의 문제를 깊이 고찰하고 해결 하였습니다.</p>,
];

const Greeting = () => {
  return (
    <>
      <MainLayout height={window.innerHeight - 637}>
        <GreetingContainer>
          <GreetingTitle>
            <h1>인사말</h1>
            {/* <p>빠르게 발전하고 있는 전기차 미래</p> */}
            {/* <p>FEC가 여러분과 함께 만들어갑니다.</p> */}
          </GreetingTitle>
          <GreetingText>
            {greetingData.map((item) => {
              return <p>{item}</p>;
            })}
            <p>(주)FEC 직원 일동</p>
          </GreetingText>
          {/* </GreetingImage> */}
        </GreetingContainer>
      </MainLayout>
      <Footer />
    </>
  );
};

export default Greeting;
