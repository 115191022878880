import { styled } from "styled-components";
import { TechnicalLayout } from "../style/Technical.style";

export const TechnicalVideoLayout = styled(TechnicalLayout)`
  max-width: 1400px;
  /* height: ${(props) => `${props.height}px`}; */
`;
export const TechcnicalVideoTitle = styled.h1`
  margin-top: 50px;
  font-size: 30px;
  font-weight: 600;
`;
export const TechnicalVideoContent = styled.div`
  margin: 50px 0;
  width: 100%;
  max-width: 800px;
  height: 400px;
  border-radius: 8px;
  & > video {
    border-radius: 8px;
  }
  @media screen and (max-width: 1024px) {
    height: 200px;
  }
`;

export const TechnicalVideoText = styled.div`
  width: 100%;
  max-width: 1400px;
  font-size: 20px;
  line-height: 25px;
  margin-top: 80px;
  color: #646464;
`;
