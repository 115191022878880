import {
  BrowserRouter,
  Route,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import AuthSignup from "../components/Auth/AuthSignup/AuthSignup";
import AuthLogin from "../components/Auth/AuthLogin/AuthLogin";
import Home from "../components/Home/Home";
import ServiceField from "../components/Service/ServiceField";
import AuthProfile from "../components/Auth/AuthProfile/AuthProfile";
import Greeting from "../components/CompanyIntroduction/Greeeting/Greeting";
import History from "../components/CompanyIntroduction/HIstory/History";
import LocationGuide from "../components/CompanyIntroduction/LocationGuide/LocationGuide";
import BusinessIntroduction from "../components/Business/Introduction/BusinessIntroduction";
import Mobile from "../components/Business/Mobile/Mobile";
import FieldDetail from "../components/Service/components/FieldDetail/FieldDetail";
import Notice from "../components/Notice/Notice";
import Post from "../components/Notice/Post/Post";
import HeaderWithMenu from "../components/Layout/HeaderWidthMenu";
import Inquiry from "../components/Notice/Inquiry/Inquiry";
import AuthEdit from "../components/Auth/AuthEdit/AuthEdit";
import UsageHistory from "../components/Auth/UsageHistory/UsageHistory";
import ChargeAddress from "../components/ChargeAddress/ChargeAddress";
import TechnicalVideo from "../components/Technical/TechnicalVideo/TechnicalVideo";
import TechnicalIntroduce from "../components/Technical/TechnicalIntroduce/TechnicalIntroduce";
import Certificate from "../components/Technical/Certificate/Certificate";
import Patent from "../components/Technical/Patent/Patent";

export const router = createBrowserRouter([
  {
    path: "/",

    children: [{ index: true, element: <Home /> }],
  },
  {
    path: "/notice",
    element: (
      <HeaderWithMenu
        menutext01="공지사항"
        url01="/notice"
        menutext02="문의사항"
        url02="/notice/inquiry"
        text="고객센터"
      />
    ),
    children: [
      { index: true, element: <Notice /> },
      { path: "inquiry", element: <Inquiry /> },
      { path: ":id", element: <Post /> },
    ],
  },
  {
    // 로그인,회원가입
    path: "auth",

    element: (
      <HeaderWithMenu
        menutext01="로그인"
        url01="/auth/login"
        menutext02="회원가입"
        text="로그인/회원가입"
        url02="/auth/signup"
      />
    ),
    children: [
      { path: "login", element: <AuthLogin /> },
      { path: "signup", element: <AuthSignup /> },
      { path: "profile", element: <AuthProfile /> },
      { path: "edit", element: <AuthEdit /> },
      { path: "usagehistory", element: <UsageHistory /> },
    ],
  },
  {
    // 서비스분야
    path: "service",
    element: (
      <HeaderWithMenu
        text="서비스내용"
        menutext01="서비스내용"
        url01="/service/field"
      />
    ),
    children: [
      { path: "field", element: <ServiceField /> }, // 서비스분야
      { path: "field/:fiedlId", element: <FieldDetail /> }, // 서비스분야 내용 링크
    ],
  },

  {
    // 회사소개
    path: "/company",
    element: (
      <HeaderWithMenu
        menutext01="인사말"
        url01="/company/greeting"
        menutext02="회사연혁"
        url02="/company/history"
        menutext03="사업장 위치"
        url03="/company/locationguide"
        text="회사소개"
      />
    ),
    children: [
      { path: "greeting", element: <Greeting /> }, // 대표인사말
      { path: "history", element: <History /> }, // 회사연혁
      { path: "locationguide", element: <LocationGuide /> }, //오시는길
    ],
  },
  {
    // 사업 안내
    path: "/business",
    element: (
      <HeaderWithMenu
        menutext01="사업내용"
        url01="/business/introduction"
        menutext02="모바일 웹"
        url02="/business/mobile"
        text="사업안내"
      />
    ),
    children: [
      { path: "introduction", element: <BusinessIntroduction /> }, // 사업내용
      { path: "mobile", element: <Mobile /> }, // 모바일 , (모바일 사용방법, 장단점 등등)
    ],
  },
  {
    path: "/charge",
    element: (
      <HeaderWithMenu
        menutext01="충전소 위치"
        url01="/charge/address"
        text="충전소 위치"
      />
    ),
    children: [
      { path: "address", element: <ChargeAddress /> }, // 충전소 위치
    ],
  },
  {
    path: "/technical",
    element: (
      <HeaderWithMenu
        menutext01="사업 내용"
        url01="/technical/introduce"
        menutext02="기술 동영상"
        url02="/technical/video"
        text="기술 설명 동영상"
        menutext03="인증서 등"
        url03="/technical/certificate"
        menutext04="특허"
        url04="/technical/patent"
      />
    ),
    children: [
      { path: "introduce", element: <TechnicalIntroduce /> },
      {
        path: "video",
        element: <TechnicalVideo />,
      },
      { path: "certificate", element: <Certificate /> },
      { path: "patent", element: <Patent /> },
    ],
  },
]);

export const Rou = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/notice/inquiry" element={<Inquiry />} />
        <Route path="/notice/:id" element={<Post />} />
        <Route path="/auth/login" element={<AuthLogin />} />
        <Route path="/auth/signup" element={<AuthSignup />} />
        <Route path="/auth/profile" element={<AuthProfile />} />
        <Route path="/auth/edit" element={<AuthEdit />} />
        <Route path="/auth/usagehistory" element={<UsageHistory />} />
        <Route path="/service/field" element={<ServiceField />} />
        <Route path="/service/field/:fiedlId" element={<FieldDetail />} />
        <Route path="/company/greeting" element={<HeaderWithMenu />} />
        <Route path="/company/history" element={<HeaderWithMenu />} />
        <Route path="/company/locationguide" element={<HeaderWithMenu />} />
        <Route
          path="/business/introduction"
          element={<BusinessIntroduction />}
        />
        <Route path="/business/mobile" element={<Mobile />} />
        <Route path="/charge/address" element={<ChargeAddress />} />
        <Route path="/technical/video" element={<TechnicalVideo />} />
      </Routes>
    </BrowserRouter>
  );
};
