import { styled } from "styled-components";

export const FieldContainer = styled.div`
    width:100%;
    height:100%;
`
export const FieldTitle = styled.div`
    height:55px;
    display:flex;
    align-items:center;
    border-top:1px solid black;
    background-color:#f8f8f8;
    h2{
        font-size:19px;
        padding-left:22px;
    }
`
export const WriterContainer = styled.div`
    padding:17px 0px;   
    display:flex;
    border-bottom:1px solid #eee;
    justify-content:space-between;
    .writer-container{
        display:flex;
        p:nth-child(1){
            color:#6666;
            padding-right:10px;
        }
    }
`
export const DetailContainer = styled.div`
    margin-top:49px;
`
export const DetailImage = styled.div`
    width:222px;
    height:222px;
    background-color:red;
`
export const DetailText = styled.div`
    margin-top:22px;
    line-height:28px;
`
export const ListBtn = styled.div`
    width:75px;
    padding:19px 0px;
    font-weight:500;
    text-align: center;
    border:1px solid #d1d1d1;
    margin-left:auto;
    color:#777;
`