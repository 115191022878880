import { styled } from "styled-components";

export const AuthBtn = styled.button`
    border:none;
    width:222px;
    padding:20px;
    font-size:17px;
    background-color:black;
    color:white;
    display:flex;
    justify-content:center;
    &:hover{
        background-color:rgba(0,0,0,0.4);
        transition:all 0.2s ease-in-out;
    }
`