import React from "react";
import { FormContainer } from "./style";


const AuthForm = ({ children, onSubmit }) => {
    return (
        <FormContainer onSubmit={onSubmit}>
            {children}
        </FormContainer>
    )
}

export default AuthForm