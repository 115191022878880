import styled from "styled-components";
import { HeaderContainer, HeaderLayout } from "../style";

export const NavigateBox = styled.ul`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavigateItem = styled.li`
  width: 200px;
  & > a {
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
`;
export const NavigateSubList = styled.ul`
  width: 200px;
  /* margin-right: 200px; */
  /* position: absolute; */
  /* line-height: 18px; */
  /* width: 100%; */
  font-size: 15px;
  display: none;
  flex-direction: column;
  opacity: 1;
  transition: all 0.8s ease-in-out;
`;

export const TechnicalVideo = styled.div`
  transition: all 0.8s ease-in-out;
  opacity: 1;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  display: block;
  visibility: hidden;
`;

export const NavigateSubBox = styled.div`
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 0px;
  position: absolute;
  top: 80px;
  left: 0;
  background: transparent;
  z-index: 9999;
  display: flex;
  & > span {
    transition: all 3s ease-in-out;
    font-size: 20px;
    display: none;
  }
  ${HeaderContainer}:hover & {
    height: 200px;
    /* border-bottom: 1px solid #969696; */
    & > div > div > ${NavigateSubList} {
      display: block;
    }
    & > div > ${TechnicalVideo} {
      visibility: hidden;
    }
  }
`;

export const NavigateSubListItem = styled.li`
  line-height: 25px;
  color: black;
  & > span {
    font-size: 30px;
  }
  & > a {
    color: white;
    &:hover {
      color: #969696;
    }
    font-size: 18px;
    & > span {
      font-size: 100px;
    }
  }
`;

// 모바일

export const MobileNavigateBack = styled.div`
  display: none;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 100px;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export const MobileNavigateBox = styled.div`
  width: 100%;
  height: 300px;
  background-color: white;
`;

export const MobileNavigateList = styled.ul`
  padding: 20px;
`;

export const MobileNavigateItem = styled.li`
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 15px;
`;
