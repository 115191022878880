import React, { useState } from "react";
import AuthForm from "../../common/AuthForm/AuthForm";
import AuthInput from "../../common/AuthInput/AuthInput";
import { InputContainer } from "../../common/style";
import { useForm } from "react-hook-form";
import { AuthSignupApi } from "../../../../api/api";
import AuthButton from "../../common/AuthButton/AuthButton";
import AuthErrorMsg from "../../common/AuthErrorMsg/AuthErrorMsg";


const CertificationNumber = ({ certification }) => {
    const { register, handleSubmit, formState: { errors }, setError } = useForm(); // react-hook-form 라이브러리
    const [phoneNumber, setPhoneNumber] = useState('');  // inputValue => phone number save
    const [isVerified, setIsVerified] = useState(false); // certification true

    const { isLoading, isError, postData } = AuthSignupApi();

    // 인증번호6자리 인증 
    const handlerCertificationPost = async (data) => {
        const response = await postData(
            process.env.REACT_APP_API_VERIFY_VRCODE,
            data,
            ''
        );
        console.log(data);
        if (response) {
            certification(true); // 인증 완료되면 정보입력란으로 이동
        }

    }

    // 휴대폰번호 인증
    const handlerPhoneNumberPost = async (data) => {
        const response = await postData(
            process.env.REACT_APP_API_VERIFY_REGISTER,
            data,
            ''
        );
        setPhoneNumber(data.phone)
        if (isError) {
            setIsVerified(false); // 폰번호가 확인되면 인증번호로 이동
        }else if(!isError){
            setIsVerified(true);
        }
    }

    return (
        <>
            {isVerified ?
                <AuthForm onSubmit={handleSubmit(handlerCertificationPost)}>
                    <InputContainer>
                        <AuthInput
                            type='number'
                            text="인증받을 휴대폰 번호"
                            placeholder="휴대폰 번호"
                            register={register('phone', {
                                required: '휴대폰 번호를 입력해주십시오.'
                            })}
                            value={phoneNumber ? phoneNumber : null}
                        />
                    </InputContainer>
                    <InputContainer>
                        <AuthInput
                            type='number'
                            text="인증번호"
                            placeholder="인증번호 6자리"
                            register={register('verify', {
                                required: '인증번호를 입력해주십시오.',
                                minLength: {
                                    value: 6,
                                    message: '인증번호는 최소 6자리 입니다.'
                                }
                            })}
                        />
                    </InputContainer>
                    <AuthButton
                        isLoading={isLoading}
                        text='인증하기'
                        type='submit'
                    />
                </AuthForm>
                :
                <AuthForm onSubmit={handleSubmit(handlerPhoneNumberPost)}>
                    <InputContainer>
                        <AuthInput
                            type='number'
                            text="인증받을 휴대폰 번호"
                            placeholder="휴대폰 번호"
                            register={register('phone', {
                                required: '휴대폰 번호를 입력해주십시오.',
                                minLength: {
                                    value: 11,
                                    message: '휴대폰번호는 11자리 이상 입니다.'
                                }
                            })}
                        />
                    </InputContainer>

                   
                    <AuthButton
                        isLoading={isLoading}
                        text='인증번호 받기'
                        type='submit'
                    />
                </AuthForm>
            }
                {/* error message */}
                {errors.phone && <AuthErrorMsg>{errors.phone.message}</AuthErrorMsg>}
                {errors.verify && <AuthErrorMsg>{errors.verify.message}</AuthErrorMsg>}
                {isError && <AuthErrorMsg>{isError.error}</AuthErrorMsg>}
        </>

    )
}

export default CertificationNumber;