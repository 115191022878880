import { styled } from "styled-components";

export const Table = styled.table`
    margin:0 auto;
    width:100%;
`

export const THeader = styled.th`
    padding:10px 0px;
    border-bottom:1px solid rgba(0,0,0,0.5);
`
export const Trow = styled.tr`

`
export const Tdescription = styled.td`
    padding:30px 0px;
    text-align:center;

`


