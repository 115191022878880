export const content = [
  {
    title: "회사소개",
    url: "/company/greeting",
    subContent: [
      {
        name: "인사말",
        url: "/company/greeting",
      },
      {
        name: "회사 연혁",
        url: "/company/history",
      },
      {
        name: "사업장 위치",
        url: "/company/locationguide",
      },
    ],
  },
  {
    title: "사업 내용 및  기술",
    url: "/technical/introduce",
    subContent: [
      {
        name: "사업 내용",
        url: "/technical/introduce",
      },
      {
        name: "기술 동영상",
        url: "/technical/video",
      },

      {
        name: "인증서 등",
        url: "/technical/certificate",
      },
      {
        name: "특허",
        url: "/technical/patent",
      },
    ],
  },
  {
    // title: '서비스분야',
    // url: '/service/field'
    title: "이모저모",
    url: "/",
    subContent: [
      {
        name: "연구개발 전경",
        url: "/",
      },
      {
        name: "사업장 설치",
        url: "/",
      },
      {
        name: "전시회 및  홍보",
        url: "/",
      },
    ],
  },
  {
    title: "회원가입",
    url: "https://fecmobile.co.kr/users/account",
    subContent: [
      //   {
      //     name: "휴대폰으로 QR을 찍으세요.",
      //   },
      //   { name: <span class="material-symbols-outlined">qr_code</span> },
    ],
  },
  //   {
  //     title: "고객센터",
  //     url: "/notice",
  //     subContent: [
  //       {
  //         name: "공지사항",
  //         url: "/notice",
  //       },
  //       {
  //         name: "문의사항",
  //         url: "/notice/inquiry",
  //       },
  //     ],
  //   },
];
