import { styled } from "styled-components";

export const MobileImage = styled.div`
    width:100%;
    height:332px;
    background:red;
    margin-top:30px;
    height:316px;
    background-image: url('/img/p11_img.jpg');
    background-size: cover;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ImageTitleContainer = styled.div`
    width:885px;
    height:227px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color:white;
    line-height: 30px;
    .big-text{
        margin-top:30px;
        font-size:20px;
        text-align:center;
    }
`

export const AdvantagesContainer = styled.div`
    margin-top:70px;
    text-align: center;
`

export const AdvantagesTitle = styled.div`
    .advantages-title{
        font-size:30px;
        span{
            font-weight:600;
        }
        margin-bottom:20px;
    }
    .advantages-text{
        line-height:22px;
        color:#777;
    }
    .mobile-address{
        margin-top:10px;
    }
`

export const AdvantagesCardContainer = styled.div`
    display:flex;
    justify-content: space-between;
    margin-top:33px;
`
