import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";


// thunk redux에서 비동기로 사용
export const fetchUserInfo = createAsyncThunk(
    'userInfo/fetchUserInfo',
    async () => {
        // cookie에 저장되어있는 token값 
        const token = Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_USER_INFO, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${token}`
                },
            })
            const responseData = await response.json();
            if (response.status === 200) {
                return responseData;
            } else if (response.status === 400) {
                return responseData;
            } else if (response.status === 404) {
                return responseData;
            } else if (response.status === 409) {
                return responseData;
            }
        } catch (error) {
            console.log(error);
        }
    }
);


//initialState 초깃값 설정
const initialState = {
    userInfo: null,
    loading: false,
    error: null
}

// createSlice는 객체를 인자로 받고 객체에는 name, initialState, reducers를 필수 요소
// name: 이름, initialState: 초기 상태, reducers: 메소드(함수)
const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {},

    // extraReducers로 비동기 관리
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
})

export default userInfoSlice.reducer;