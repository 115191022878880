import React, { Fragment, useEffect } from "react";
import Footer from "../Footer/Footer";
import {
  CardContainer,
  MenuCard,
} from "../CompanyIntroduction/Greeeting/style";
import { Field, FieldContainer, Image, Text } from "./style";
import SubMenuCard from "../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../common/style/styled";

const ServiceField = () => {
  return (
    <>
      <MainLayout>
        <Text>내용 준비중 입니다. 조금만 기다려주세요.</Text>
        <Field></Field>
      </MainLayout>
      <Footer />
    </>
  );
};

const informationArr = [
  {
    imgsrc: "/img/101.png",
    alt: "주차장에 커넥터와 전선, 스위치 설치",
    title: "주차장에 커넥터와 전선, 스위치 설치",
    description: (
      <>
        <div>
          <p>주차장의 각 주차면에 차량 충전용 커넥터를 배치하고 이와</p>
          <p>
            연결할 전선과 경로 형성 스위치를 설치합니다. 충전기는 커넥터보다
          </p>
          <p> 더 적게 설치해도 충분합니다.</p>
        </div>
        <div>
          <p>평소에는 커넥터와 충전기는 연결되어 있지 않지만 차량을 </p>
          <p> 충전할 때 경로 형성 스위치가 커넥터와 충전기가 전선과 연결</p>
          <p>되도록 작동합니다.</p>
        </div>
      </>
    ),
  },

  {
    imgsrc: "/img/102.png",
    alt: "㈜FEC만의 고유 기술, 경로 형성 스위치",
    title: "㈜FEC만의 고유 기술, 경로 형성 스위치",
    description: (
      <>
        <div>
          <p>전선이 교차하는 곳에 연결되어 있는 경로 형성 스위치는 최대</p>
          <p>네 방향을 이어주는 6가지의 릴레이를 지니고 있습니다. </p>
        </div>
        <div>
          <p>충전소를 관리하는 서버가 충전 신청에 맞춰 경로 형성 정보를</p>
          <p>
            스위치에 전달하면 이에 맞춰 릴레이를 작동시켜 경로를 형성합니다.
          </p>
        </div>
      </>
    ),
  },

  {
    imgsrc: "/img/103.png",
    alt: "자동으로 충전 경로를 형성하는 알고리즘",
    title: "자동으로 충전 경로를 형성하는 알고리즘",
    description: (
      <>
        <div>
          <p>전기차 사용자가 충전 신청을 하면 충전 신청 내역에 따라</p>
          <p>알고리즘을 통해 경로를 형성하여 충전을 진행합니다.</p>
        </div>
        <div>
          <p>모든 충전기가 사용 중이면 충전 대기열에 등록합니다.</p>
          <p>이후 앞서 충전하던 차량이 충전 완료되면 대기열에 따라</p>
          <p>다음 차량을 자동으로 충전합니다.</p>
        </div>
      </>
    ),
  },
];
export default ServiceField;

{
  /* <FieldContainer>
                        <Image>
                            <div>1</div>
                        </Image>
                        <div>충전기 설치</div>
                    </FieldContainer>

                    <FieldContainer>
                        <Image>
                            <div>1</div>
                        </Image>
                        <div>전기차 충전 운영</div>
                    </FieldContainer>
                    <FieldContainer>
                        <Image>
                            <div>1</div>
                        </Image>
                        <div>충전기 유지보수</div>
                    </FieldContainer> */
}
