import { useLoaderData, useLocation } from "react-router-dom";
import SubMenuCard from "../common/SubMenuCard/SubMenuCard";
import SmHeader from "./SmHeader/SmHeader";
import { useSelector } from "react-redux";
import Greeting from "../CompanyIntroduction/Greeeting/Greeting";
import History from "../CompanyIntroduction/HIstory/History";
import LocationGuide from "../CompanyIntroduction/LocationGuide/LocationGuide";
import { MainBackImg } from "../Home/IndexContent/style";
import Header from "./Header/Header";
import { BackImg } from "../common/style/styled";

const HeaderWithMenu = ({
  menutext01,
  menutext02,
  menutext03,
  menutext04,
  url01,
  url02,
  url03,
  url04,
  text,
}) => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const location = useLocation();
  const pathname = location.pathname;

  const content =
    pathname === "/company/greeting" ? (
      <Greeting />
    ) : pathname === "/company/history" ? (
      <History />
    ) : pathname === "/company/locationguide" ? (
      <LocationGuide />
    ) : null;

  return (
    <>
      <Header userInfo={userInfo} text={text} />
      <BackImg>
        <img src="/img/mainBanner/main_banner_01.jpg" alt="backImg" />
      </BackImg>
      <SubMenuCard
        menutext01={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? "정보관리"
            : menutext01
        }
        url01={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? "/auth/profile"
            : url01
        }
        menutext02={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? "이용내역"
            : menutext02
        }
        url02={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? "/auth/usagehistory"
            : url02
        }
        menutext03={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? ""
            : menutext03
        }
        url03={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? ""
            : url03
        }
        menutext04={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? ""
            : menutext04
        }
        url04={
          userInfo?.user &&
          (pathname === "/auth/profile" || pathname === "/auth/usagehistory")
            ? ""
            : url04
        }
      />
      {/* {content} */}
    </>
  );
};

export default HeaderWithMenu;
