import {
  TechnicalVideoLayout,
  TechnicalVideoContent,
  TechnicalVideoText,
  TechcnicalVideoTitle,
} from "./TechnicalVideo.style";
import januaryMakeVideo from "./januaryMakeVideo.mp4";
import julyMakeVideo from "./julyMakeVideo.mp4";
import Footer from "../../Footer/Footer";
import { MainLayout } from "../../common/style/styled";

const TechnicalVideo = () => {
  return (
    <>
      <MainLayout>
        {" "}
        <TechnicalVideoLayout height={window.innerHeight - 750}>
          <TechcnicalVideoTitle>
            2023년 1월 제작 기술 동영상
          </TechcnicalVideoTitle>
          <TechnicalVideoContent>
            <video
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
              // autoPlay
              // muted
              // loop
              controls
            >
              <source src={januaryMakeVideo} type="video/mp4" />
            </video>
          </TechnicalVideoContent>
          <TechcnicalVideoTitle>
            2023년 7월 제작 기술 동영상
          </TechcnicalVideoTitle>
          <TechnicalVideoContent>
            <video
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
              // autoPlay
              // muted
              // loop
              controls
            >
              <source src={julyMakeVideo} type="video/mp4" />
            </video>
          </TechnicalVideoContent>
          {/* <TechnicalVideoText>
          ㈜FEC는 앞으로 다가오는 전기차 시대와 발맞춰 편하고 손쉬운 전기차 충전
          서비스를 추구합니다. FEC는 「Freedom of Electric Cars」, 즉 「전기차의
          자유」를 의미합니다. ㈜FEC는 회사명에 따라 전기차 사용자를 늘 귀찮게
          하는 충전 방법에 대한 걱정이 없는 충전 시스템을 설계합니다. 현재 개발
          및 서비스 중인 자율 주차 충전 시스템은 주차장에 남아서 다음 순번을
          기다릴 필요가 없는 급속 충전 시스템입니다. 비어있는 아무 주차면에 주차
          후 인근 커넥터를 연결하여 충전을 신청하면 대기열 차례대로 자신의
          차례가 왔을 때 충전을 시작합니다. 경로 형성 스위치와 전선망을 사용하는
          해당 시스템은 소수의 충전기로도 다수의 커넥터와 연결 및 충전이
          가능합니다. 이러한 자율 주차 충전 시스템은 아무리 대기열이 많아도
          1시간 내에 충전할 수 있도록 설계했습니다.
        </TechnicalVideoText> */}
        </TechnicalVideoLayout>
      </MainLayout>
      {/* <SmHeader /> */}

      <Footer />
    </>
  );
};

export default TechnicalVideo;
