import { styled } from "styled-components";

export const Card = styled.div`
    width: 332px;
    height:332px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e1e1e1;
    border-bottom:10px solid #e9e9e9;
    
`

export const Icon = styled.div`
    width:104px;
    height:104px;
    border-radius:50%;
    background-color:#f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const CardTitle = styled.div`
    text-align: center;
    margin-top:12px;
    line-height:22px;
    font-weight:bold;
`
export const CardText = styled.div`
    margin-top:20px;
    font-size:15px;
    line-height:22px;
    color:#777;
`