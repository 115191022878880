import { RouterProvider } from "react-router-dom";
import { Rou, router } from "./router/router";
import { Reset } from "styled-reset";
import { Fragment } from "react";
import { Provider } from "react-redux";
import store from "./components/store/store";
import { styled } from "styled-components";

export const AppLayout = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  width: 100%;
  /* height: 100vh; */
  overflow: auto;
`;
function App() {
  return (
    <AppLayout>
      {/* store -> 저장소 store안에는 여러개의 slice로 이루어져있음 */}
      <Provider store={store}>
        <Reset />
        <RouterProvider router={router} />
        {/* <Rou /> */}
      </Provider>
    </AppLayout>
  );
}

export default App;
