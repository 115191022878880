import React, { useEffect, useState } from "react";
import {
  CardContainer,
  MenuCard,
} from "../CompanyIntroduction/Greeeting/style";
import {
  Container,
  NoticeContainer,
  Post,
  PostContainer,
  PostInfo,
  Text,
} from "./style";
import SubMenuCard from "../common/SubMenuCard/SubMenuCard";
import { MainLayout } from "../common/style/styled";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

const Notice = () => {
  const [isNotice, setIsNotice] = useState([]);
  const [isError, setIsError] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_NOTICE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      if (response.status === 200) {
        return setIsNotice(responseData);
      }
      if (response.status === 400) {
        return setIsError(responseData.error);
      }
    };
    fetchData();
  }, []);
  const noticeObject = Object.values(isNotice);

  return (
    <>
      <MainLayout>
        <Container>
          <NoticeContainer>
            <PostInfo>
              <div className="post-info__number">
                <span>No</span>
              </div>
              <div className="post-info__title">
                <span>제목</span>
              </div>
              <div className="post-info__author">
                <span>작성자</span>
                <span>등록일</span>
              </div>
            </PostInfo>
            {noticeObject.reverse().map((item, idx) => {
              const datetime = new Date(item && item.posttime);
              const day = datetime.getDate();
              const month = (datetime.getMonth() + 1)
                .toString()
                .padStart(2, "0");
              const year = datetime.getFullYear();

              return (
                <Post>
                  <PostContainer key={idx}>
                    <div className="post-info__number">
                      <span>{item.id && item.id}</span>
                    </div>
                    <div className="post-info__title">
                      <Link to={`/notice/${item.id}`}>
                        <span>{item && item.title}</span>
                      </Link>
                    </div>
                    <div className="post-info__author">
                      <span>관리자</span>
                      <span className="post-date__time">
                        {year}-{month}-{day}
                      </span>
                    </div>
                  </PostContainer>
                </Post>
              );
            })}
          </NoticeContainer>
        </Container>
      </MainLayout>
      <Footer />
    </>
  );
};

export default Notice;
